import { makeObservable, observable, runInAction, toJS } from 'mobx';
import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import { Session, SessionService, TScriptId, TSessionId, TShootId, TUpdateSessionRequest } from '/lib/api';
import { ISODateString } from '/src/types';

export class SessionStore extends BaseStore {
  currentSession?: Session;
  sessionList: {
    [key: TShootId]: Session[]
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.sessionList = {};
    makeObservable(this, {
      currentSession: observable,
      sessionList: observable,
    });
  }

  getLastSessionDate(shootId: TShootId): string | undefined {
    return this.sessionList[shootId].toSorted((a, b) => ((a.date ?? '') > (b.date ?? '') ? -1 : 1))[0]?.date ?? '';
  }

  getSessionDates = (shootId: TShootId, sessionId?: TSessionId): string[] => {
    return (this.sessionList[shootId]
      ?.filter((se) => se.id !== sessionId)
      .toSorted((a, b) => ((a.date ?? '') > (b.date ?? '') ? -1 : 1))
      .map((se) => se.date ?? '') ?? []) as string[];
  };

  setCurrentSession(session?: Session) {
    runInAction(() => (this.currentSession = session));
  }

  setSessionList(shootId: TShootId, sessionList: Session[]) {
    runInAction(() => (this.sessionList[shootId] = sessionList));
  }

  listSessions = async (shootId: string | undefined) => {
    if (!shootId) return;
    try {
      const { sessions } = await SessionService.listSessions(shootId);
      console.log('setting sessionList',shootId, sessions);
      this.setSessionList(shootId, sessions);
      return sessions;
    } catch (e) {
      this.handleError(e);
    }
  };

  getSession = async (sessionId: TSessionId, relations = 'activities,session_estimate,script,shoot') => {
    try {
      const { session } = await SessionService.getSession(sessionId, relations);
      const a = await this.rootStore.sessionActivityStore.listSessionActivities(sessionId);
      if (session) {
        if (a) session.activities = a;
        return session;
      }
    } catch (e) {
      this.handleError(e);
    }
  };

  createSession = async (date: ISODateString, shootId: TShootId, scriptId?: TScriptId) => {
    if (!shootId || !date) return;
    try {
      const { session } = await SessionService.createSession({ date, shootId, scriptId });
      const newSession = await this.getSession(session?.id);
      if (newSession) {
        runInAction(() => {
          this.sessionList[shootId].unshift(newSession);
        });
        return newSession;
      }
    } catch (e) {
      this.handleError(e);
    }
  };

  updateSession = async (shootId: TShootId, sessionId: TSessionId, update: TUpdateSessionRequest) => {
    const i = this.sessionList[shootId]?.findIndex(({ id }) => id === sessionId);
    console.log(sessionId, toJS(this.sessionList));
    if (i === undefined || i === -1) return;
    try {
      const { session } = await SessionService.updateSession(sessionId, update);
      const updatedSession = await this.getSession(session.id);
      if (updatedSession) {
        runInAction(() => {
          this.sessionList[shootId].splice(i, 1, updatedSession);
        });
        return updatedSession;
      }
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteSession = async (shootId: TShootId, sessionId: TSessionId) => {
    try {
      await SessionService.deleteSession(sessionId);
      if (sessionId === this.currentSession?.id) this.setCurrentSession(undefined);
      runInAction(() => {
        this.sessionList[shootId] = this.sessionList[shootId]?.filter((p) => p.id !== sessionId);
      });
    } catch (e) {
      this.handleError(e);
    }
  };
}
