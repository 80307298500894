import { Box, Button, Layer, Text } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { UsageTypeSelect } from '../..';
import { TCommercialId, UsageType } from '/lib/api';

export interface ICreateUsageModalProps {
  commercialId: TCommercialId;
  addUsage: (commercialId: TCommercialId, usageType: UsageType) => void;
  setShowModal: (isVisible: boolean) => void;
}

export const CreateUsageModal = observer(({ commercialId, addUsage, setShowModal }: ICreateUsageModalProps) => {
  const [type, setType] = useState<UsageType>();

  return (
    <Layer
      onEsc={() => setShowModal(false)}
      onClickOutside={() => setShowModal(false)}
      position="center"
      modal={true}
      margin="large"
    >
      <Box
        direction="column"
        align="start"
        pad="small"
        justify="around"
        width="medium"
        height="medium"
        background="light-1"
        round="small"
      >
        <Box gap="small" pad="small" justify="center" fill="horizontal">
          <Box fill="horizontal">
            <Text>Usage Type:</Text>
          </Box>
          <Box fill="horizontal">
            <UsageTypeSelect val={type} onChange={(val) => setType(val)} />
          </Box>
        </Box>

        <Button
          pad="small"
          margin="small"
          hoverIndicator
          onClick={() => (type ? addUsage(commercialId, type) : {})}
          disabled={!type}
          label="Add Usage"
        />
      </Box>
    </Layer>
  );
});
