/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeObservable, observable } from 'mobx';
import { toast } from 'react-toastify';
import {
  AccountStore,
  AdminStore,
  CostumeStore,
  EstimateStore,
  PerformerStore,
  ProjectStore,
  PropStore,
  RoleStore,
  SessionActivityStore,
  SessionStore,
  ShootStore,
  UsageStore,
  UserStore,
} from '.';
import { CancelablePromise } from '/lib/api';
import { OpenAPI } from '/lib/api/core/OpenAPI';
import { config } from '/src/config';

export class RootStore {
  accountStore: AccountStore;
  adminStore: AdminStore;
  estimateStore: EstimateStore;
  costumeStore: CostumeStore;
  propStore: PropStore;
  roleStore: RoleStore;
  performerStore: PerformerStore;
  projectStore: ProjectStore;
  sessionStore: SessionStore;
  sessionActivityStore: SessionActivityStore;
  shootStore: ShootStore;
  userStore: UserStore;
  usageStore: UsageStore;
  requests: CancelablePromise<any>[] = [];

  constructor() {
    OpenAPI.BASE = `${config.env.apiHost}:${config.env.apiPort}/${config.env.apiBase}/${config.env.apiVersion}`;
    this.accountStore = new AccountStore(this);
    this.adminStore = new AdminStore(this);
    this.estimateStore = new EstimateStore(this);
    this.costumeStore = new CostumeStore(this);
    this.propStore = new PropStore(this);
    this.performerStore = new PerformerStore(this);
    this.roleStore = new RoleStore(this);
    this.projectStore = new ProjectStore(this);
    this.sessionActivityStore = new SessionActivityStore(this);
    this.sessionStore = new SessionStore(this);
    this.shootStore = new ShootStore(this);
    this.userStore = new UserStore(this);
    this.usageStore = new UsageStore(this);

    makeObservable(this, {
      accountStore: observable,
      adminStore: observable,
      estimateStore: observable,
      costumeStore: observable,
      propStore: observable,
      roleStore: observable,
      performerStore: observable,
      projectStore: observable,
      sessionStore: observable,
      sessionActivityStore: observable,
      shootStore: observable,
      userStore: observable,
      usageStore: observable,
    });
  }
  handleError = (error: any, customMsg?: string) => {
    // console.log('error: ', JSON.stringify(error, null, 2));
    if (error.response?.status === 401) {
      customMsg = 'Your session has expired. Please log in again';
      this.cancelRequests();
      this.clearRequests();
    } else {
      console.error(error);
      throw error;
    }
    toast.error(customMsg || error.response?.data?.message || error.message || 'An error occurred');
  };
  cancelRequests = () => {
    this.requests.forEach((request) => request.cancel());
    this.clearRequests();
  };
  cancelRequest = (request: CancelablePromise<any>) => {
    request.cancel();
    this.removeRequest(request);
  };
  addRequest = async (request: CancelablePromise<any>) => {
    this.requests.push(request);
    return await request.then((res) => {
      this.removeRequest(request);
      return res;
    });
  };
  removeRequest = (request: CancelablePromise<any>) => {
    this.requests = this.requests.filter((req) => req !== request);
  };
  clearRequests = () => {
    this.requests = [];
  };
  get isRequesting() {
    return this.requests.length > 0;
  }
  get hasRequests() {
    return this.requests.length > 0;
  }
  get hasNoRequests() {
    return this.requests.length === 0;
  }
}
