import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import {
  RoleActivity,
  RoleActivityService,
  SessionActivity,
  SessionActivityService,
  SessionActivityType,
  TUpdateSessionActivityRequest,
} from '/lib/api';

export class SessionActivityStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  /**
   * Session Activities
   */

  listSessionActivities = async (sessionId: string) => {
    try {
      const { sessionActivities } = await SessionActivityService.listSessionActivities(
        sessionId,
        undefined,
        undefined,
        undefined,
        'roles,role_groups,props,costumes,location,from_location,to_location,role_activities,script,role_activities.role'
      );
      return sessionActivities;
    } catch (e) {
      this.handleError(e);
    }
  };

  getSessionActivity = async (sessionActivityId: string) => {
    try {
      const { sessionActivity } = await SessionActivityService.getSessionActivity(
        sessionActivityId,
        'roles,role_groups,props,costumes,location,from_location,to_location,role_activities,script,role_activities.role'
      );
      return sessionActivity;
    } catch (e) {
      this.handleError(e);
    }
  };

  createSessionActivity = async (
    sessionId: string,
    type: SessionActivityType,
    startTime: string,
    endTime: string,
    scriptId: string
  ) => {
    if (!sessionId || !type || !startTime || !endTime || !scriptId) {
      console.warn('createSessionActivity call is missing required argument');
      return;
    }
    try {
      const { sessionActivity } = await SessionActivityService.createSessionActivity({
        sessionId,
        type,
        startTime,
        endTime,
        scriptId,
      });
      return sessionActivity;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateSessionActivity = async (sessionActivity: SessionActivity) => {
    if (!sessionActivity.id) return;
    try {
      const updateRequest: TUpdateSessionActivityRequest = {
        scriptId: sessionActivity.script?.id,
        sessionId: sessionActivity.session?.id,
        startTime: sessionActivity.start_time,
        endTime: sessionActivity.end_time,
        type: sessionActivity.type,
        roleIds: sessionActivity.roles?.map((r) => r.id),
        roleGroupIds: sessionActivity.role_groups?.map((rg) => rg.id),
        propIds: sessionActivity.props?.map((p) => p.id),
        costumeIds: sessionActivity.costumes?.map((c) => c.id),
        locationId: sessionActivity.location?.id,
        fromLocationId: sessionActivity.from_location?.id,
        toLocationId: sessionActivity.to_location?.id,
        roleActivityIds: sessionActivity.role_activities?.map((ra) => ra.id),
        isCreativeSession: sessionActivity.is_creative_session,
        isScratchTrack: sessionActivity.is_scratch_track,
      };

      await SessionActivityService.updateSessionActivity(sessionActivity.id, updateRequest);
      const fullUpdatedSessionActivity = await this.getSessionActivity(sessionActivity.id);
      // update displayed estimate values
      const est = await this.rootStore.estimateStore.getEstimate(
        this.rootStore.estimateStore.currentEstimate?.id,
        'shoot,usage_estimates,session_estimates'
      );
      est && this.rootStore.estimateStore.setCurrentEstimate(est);
      return fullUpdatedSessionActivity;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteSessionActivity = async (sessionActivityId: string) => {
    try {
      await SessionActivityService.deleteSessionActivity(sessionActivityId);
    } catch (e) {
      this.handleError(e);
    }
  };

  /**
   * Role Activities
   */

  createRoleActivity = async (newActivity: Partial<RoleActivity>) => {
    if (!newActivity.role?.id || !newActivity.session_activity?.id || !newActivity.project?.id) return;
    const request = {
      roleId: newActivity.role?.id,
      sessionActivityId: newActivity.session_activity?.id,
      projectId: newActivity.project?.id,
      isHazardousWork: newActivity.is_hazardous_work,
      isOwnStuntPerformer: newActivity.is_own_stunt_performer,
      isWetSnowSmokeDustWork: newActivity.is_wet_snow_smoke_dust_work,
    };
    try {
      const { roleActivity } = await RoleActivityService.createRoleActivity(request);
      return roleActivity;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateRoleActivity = async (updatedActivity: RoleActivity) => {
    if (!updatedActivity.id) return;
    const request = {
      isHazardousWork: updatedActivity.is_hazardous_work,
      isOwnStuntPerformer: updatedActivity.is_own_stunt_performer,
      isWetSnowSmokeDustWork: updatedActivity.is_wet_snow_smoke_dust_work,
    };
    try {
      const { roleActivity } = await RoleActivityService.updateRoleActivity(updatedActivity.id, request);
      // update displayed estimate values
      const est = await this.rootStore.estimateStore.getEstimate(
        this.rootStore.estimateStore.currentEstimate?.id,
        'shoot,session_estimates,usage_estimates'
      );
      est && this.rootStore.estimateStore.setCurrentEstimate(est);
      return roleActivity;
    } catch (e) {
      this.handleError(e);
    }
  };
}
