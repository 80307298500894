import React, { useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Text } from 'grommet';
import { env } from '../../../config/env';
import { ChatMessage, Conversation, MessageType } from '../../../../lib/api';
import { useUserStore } from '/src/context';

interface ISupportChat {
  setMessageState: React.Dispatch<React.SetStateAction<{
    conversationId?: string;
    messages: Array<Partial<ChatMessage>>;
    pending?: string;
    }>>;
  updateConversations?: (conversation: Conversation) => void;
  addConversation?: (conversation: Conversation) => void;
  messageToSend?: Partial<ChatMessage>;
  conversationId: string;
}

export const SupportChat = ({setMessageState, messageToSend, conversationId, updateConversations, addConversation}: ISupportChat) => {
  const userStore = useUserStore();
  const queryParams = {
    c: conversationId,
  };
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(`${env.apiHost}:${env.apiPort}`, 
    { queryParams, 
      protocols: ['Authorization', userStore.token],
      onError: e => {
        console.log('error', e);
      }
    });

  useEffect(() => {
    if (lastMessage !== null) {
      const data = lastMessage.data;
      const msgJson = JSON.parse(data);

      switch (msgJson.type) {
        case (MessageType.NEW_CONVERSATION): 
          addConversation && addConversation(msgJson.conversation);
          break;
        case (MessageType.UPDATED_CONVERSATION):
          updateConversations && updateConversations(msgJson.conversation);
          break;
        default:
          setMessageState((state) => ({
            ...state,
            messages: [
              ...state.messages,
              {
                ...msgJson,
              },
            ],
            conversationId: msgJson.conversation_id,
          }));
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    if (messageToSend) {
      sendJsonMessage(messageToSend);
    }
  }, [messageToSend]);


  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <Text margin={{horizontal: 'small'}} size="xsmall" alignSelf='start'> Support chat is: {connectionStatus}.  Channel: {conversationId}. </Text>
  );
};