import React, { useEffect, useRef } from 'react';
import { Box } from 'grommet';
import { ChatMessage } from './ChatMessage';
import { ChatMessage as ChatMessageInterface } from '/lib/api';
export interface IMessageListProps {
  messages: Array<Partial<ChatMessageInterface>>;
}

export const MessageList = ({ messages }: IMessageListProps) => {
  const messageListRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current?.scrollTo(0, messageListRef.current.scrollHeight);
    }
  }, [messages]);

  return <Box
    background="white"
    border={{ color: '#d9d9e3' }}
    justify="start"
    alignContent="start"
    direction="column"
    flex
    overflow={{ vertical: 'auto' }}
    ref={messageListRef}
  >
    {messages.map((message, index) => (
      <ChatMessage message={message} index={index} key={`chatMessage-${index}`} />
    ))}
  </Box>;
};

