/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConversationStatus } from '../models/ConversationStatus';
import type { TConversationId } from '../models/TConversationId';
import type { TGetConversationResponse } from '../models/TGetConversationResponse';
import type { TListConversationsResponse } from '../models/TListConversationsResponse';
import type { TUpdateConversationRequest } from '../models/TUpdateConversationRequest';
import type { TUpdateConversationResponse } from '../models/TUpdateConversationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConversationService {

    /**
     * List Conversations
     * @param status
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListConversationsResponse TListConversationsResponse
     * @throws ApiError
     */
    public static listConversations(
        status: ConversationStatus,
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListConversationsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chat',
            query: {
                'status': status,
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Get Conversation
     * @param id
     * @param relations
     * @returns TGetConversationResponse TGetConversationResponse
     * @throws ApiError
     */
    public static getConversation(
        id: string,
        relations?: string,
    ): CancelablePromise<TGetConversationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/chat/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Conversation
     * @param id
     * @param requestBody
     * @returns TUpdateConversationResponse TUpdateConversationResponse
     * @throws ApiError
     */
    public static updateConversation(
        id: TConversationId,
        requestBody: TUpdateConversationRequest,
    ): CancelablePromise<TUpdateConversationResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/chat/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Conversation
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteConversation(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/chat/{id}/delete',
            path: {
                'id': id,
            },
        });
    }

}
