import { Video } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { CommercialRow, CreateCommercialModal, TalonCard } from '../..';
import { Commercial, CommercialType, Shoot } from '/lib/api';
import { useEstimateStore, useProjectStore, useShootStore } from '/src/context';

export interface ICommercialsPanelProps {
  shoot: Shoot;
}

export const CommercialsPanel = observer(({ shoot }: ICommercialsPanelProps) => {
  /** Context **/
  const estimateStore = useEstimateStore();
  const projectStore = useProjectStore();
  const shootStore = useShootStore();

  /** State **/
  const [showAddModal, setShowAddModal] = useState(false);
  const [commercials, setCommercials] = useState<Commercial[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  // const [locations, setLocations] = useState<Location[]>([]);

  /** Methods **/
  const selectedNames = () => {
    const titles = selected.map((s) => commercials.find((item) => item.id === s)?.title).join(', ');
    return titles;
  };

  const getCommercials = async () => {
    if (!shoot) {
      setCommercials([]);
      return;
    }
    const s = await shootStore.listCommercials(shoot.id);
    setCommercials(s || []);
  };

  const chooseCommercial = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const createCommercial = async (type: CommercialType, durationInSeconds: number, title?: string) => {
    if (!type || typeof durationInSeconds !== 'number') return;

    const jobCode = projectStore.currentProject?.job_code ?? '';

    const newCommercial = await shootStore.createCommercial({
      shootId: shoot.id,
      jobCode,
      durationInSeconds,
      type,
      title: title || 'New Commercial',
    });

    if (newCommercial) {
      setCommercials([...commercials, newCommercial]);
    }
  };

  const deleteCommercials = async () => {
    // delete the selected commercials
    await Promise.all(selected.map((s) => shootStore.deleteCommercial(s)));
    // remove the deleted commercials from the list
    setCommercials(commercials.filter((commercial) => !selected.includes(commercial.id)));
    // refresh estimate totals
    await estimateStore.refreshEstimateTotals(estimateStore.currentEstimate?.id);
    setSelected([]);
  };

  /** Effects **/
  useEffect(() => {
    getCommercials();
  }, [shoot]);

  /** Render **/
  return (
    <TalonCard
      label="Commercial"
      labelPlural={`${shoot.name} Commercials`}
      newItem={() => setShowAddModal(true)}
      deleteItems={() => deleteCommercials()}
      selectedNames={selectedNames()}
      addIcon={<Video />}
    >
      {commercials.map((commercial) => (
        <CommercialRow
          key={commercial.id}
          commercialId={commercial.id}
          onChecked={() => chooseCommercial(commercial.id)}
          checked={selected.includes(commercial.id)}
        />
      ))}
      {showAddModal && <CreateCommercialModal addCommercial={createCommercial} setShowModal={setShowAddModal} />}
    </TalonCard>
  );
});
