/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateSessionRequest } from '../models/TCreateSessionRequest';
import type { TCreateSessionResponse } from '../models/TCreateSessionResponse';
import type { TGetSessionResponse } from '../models/TGetSessionResponse';
import type { TListSessionsResponse } from '../models/TListSessionsResponse';
import type { TSessionId } from '../models/TSessionId';
import type { TUpdateSessionRequest } from '../models/TUpdateSessionRequest';
import type { TUpdateSessionResponse } from '../models/TUpdateSessionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SessionService {

    /**
     * Get Session
     * @param id
     * @param relations
     * @returns TGetSessionResponse TGetSessionResponse
     * @throws ApiError
     */
    public static getSession(
        id: TSessionId,
        relations?: string,
    ): CancelablePromise<TGetSessionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Session
     * @param id
     * @param requestBody
     * @returns TUpdateSessionResponse TUpdateSessionResponse
     * @throws ApiError
     */
    public static updateSession(
        id: TSessionId,
        requestBody: TUpdateSessionRequest,
    ): CancelablePromise<TUpdateSessionResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/sessions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Session
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteSession(
        id: TSessionId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sessions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Sessions
     * @param shootId
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListSessionsResponse TListSessionsResponse
     * @throws ApiError
     */
    public static listSessions(
        shootId?: string,
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListSessionsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions',
            query: {
                'shootId': shootId,
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Session
     * @param requestBody
     * @returns TCreateSessionResponse TCreateSessionResponse
     * @throws ApiError
     */
    public static createSession(
        requestBody: TCreateSessionRequest,
    ): CancelablePromise<TCreateSessionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sessions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
