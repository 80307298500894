import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import {
  Estimate,
  EstimateService,
  EstimateStatus,
  SessionEstimateItemService,
  SessionEstimateItemUnitType,
  SessionEstimateService,
  TCreateUsageEstimateItemRequest,
  TCreateUsageEstimateRequest,
  TEstimateId,
  TProjectId,
  TSessionActivityId,
  TSessionEstimateId,
  TSessionEstimateItemId,
  TSessionId,
  TShootId,
  TUpdateEstimateRequest,
  TUpdateUsageEstimateItemRequest,
  TUsageEstimateId,
  TUsageEstimateItemId,
  UsageEstimate,
  UsageEstimateItemService,
  UsageEstimateService,
} from '/lib/api';
import { DateString, Nullable } from '/src/types';

const estimateRelations = 'session_estimates,usage_estimates,shoot,project';

export class EstimateStore extends BaseStore {
  currentEstimate?: Estimate;
  estimateList: Estimate[] = [];
  isLoading = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      currentEstimate: observable,
      estimateList: observable,
      isLoading: observable,
      canEditCurrent: computed,
      lastSessionEstimateDate: computed,
      setCurrentEstimate: action,
      setEstimateList: action,
    });
  }

  /**
   * Estimates
   */

  get canEditCurrent(): boolean {
    return !this.currentEstimate?.shoot_id && this.currentEstimate?.status === EstimateStatus.DRAFT;
  }

  setCurrentEstimate(estimate?: Estimate) {
    this.currentEstimate = estimate;
  }

  setEstimateList(estimateList: Estimate[]) {
    this.estimateList = estimateList;
  }

  listEstimates = async (projectId: TProjectId, relations?: string) => {
    if (!projectId) return;
    // TODO implement pagination
    try {
      runInAction(() => (this.isLoading = true));

      const { estimates } = await EstimateService.listEstimates(projectId, undefined, undefined, undefined, relations);
      runInAction(() => {
        this.setEstimateList(estimates.toSorted((a, b) => (a.created_at < b.created_at ? 1 : -1)));
        this.isLoading = false;
      });
    } catch (e) {
      this.handleError(e);
    }
  };

  getEstimate = async (estimateId?: TEstimateId, relations?: string) => {
    if (!estimateId) return;
    try {
      const { estimate } = await EstimateService.getEstimate(estimateId, relations);
      return estimate;
    } catch (e) {
      this.handleError(e);
    }
  };

  getEstimateTotals = async (estimateId?: TEstimateId) => {
    if (!estimateId) return;
    try {
      const totals = await EstimateService.getEstimateTotals(estimateId);
      return totals;
    } catch (e) {
      this.handleError(e);
    }
  };

  refreshEstimateTotals = async (estimateId?: TEstimateId) => {
    if (!estimateId) return;
    try {
      const totals = await this.getEstimateTotals(estimateId);
      if (totals) {
        const estimateIndex = this.estimateList.findIndex((e) => e.id === estimateId);
        if (estimateIndex > -1) {
          const list = this.estimateList;
          list[estimateIndex] = {
            ...list[estimateIndex],
            total: totals.total,
            total_plus_fringe: totals.total_plus_fringe,
          };
          this.setEstimateList(list);
        }

        if (this.currentEstimate?.id === estimateId)
          this.setCurrentEstimate({
            ...this.currentEstimate,
            total: totals.total,
            total_plus_fringe: totals.total_plus_fringe,
          });
      }
    } catch (e) {
      this.handleError(e);
    }
  };

  refreshEstimate = async (estimateId?: TEstimateId) => {
    if (!estimateId) return;
    try {
      const { estimate: updatedEstimate } = await EstimateService.getEstimate(estimateId, estimateRelations);
      if (!updatedEstimate) return;
      const estimateIndex = this.estimateList.findIndex((e) => e.id === estimateId);
      if (estimateIndex > -1) {
        const list = [...this.estimateList];
        list[estimateIndex] = updatedEstimate;
        this.setEstimateList(list);
      }

      if (this.currentEstimate?.id === estimateId) this.setCurrentEstimate(updatedEstimate);
    } catch (e) {
      this.handleError(e);
    }
  };

  createEstimate = async (projectId: TProjectId, title?: string, shootId?: TShootId) => {
    if (!projectId) return;
    try {
      const { estimate: newEstimate } = await EstimateService.createEstimate({
        projectId,
        title,
        shootId,
      });
      const estimate = await this.getEstimate(newEstimate?.id, estimateRelations);
      if (estimate) {
        runInAction(() => {
          this.estimateList.unshift(estimate);
        });
      }
      return estimate;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateEstimate = async (e: Estimate) => {
    const i = this.estimateList?.findIndex((p) => p.id === e.id);
    if (i === undefined || i === -1) return;
    const updateRequest: TUpdateEstimateRequest = {
      status: e.status,
      title: e.title,
      // version: e.version,
      shootId: e.shoot?.id,
    };
    try {
      const { estimate } = await EstimateService.updateEstimate(e.id, updateRequest);
      const updatedEstimate = await this.getEstimate(estimate?.id, estimateRelations);
      if (updatedEstimate) {
        runInAction(() => {
          this.estimateList.splice(i, 1, updatedEstimate);
          this.setCurrentEstimate(updatedEstimate);
        });
      }
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteEstimate = async (estimateId: string) => {
    try {
      await EstimateService.deleteEstimate(estimateId);
      if (estimateId === this.currentEstimate?.id) this.setCurrentEstimate(undefined);
      runInAction(() => {
        this.estimateList = this.estimateList?.filter((p) => p.id !== estimateId);
      });
    } catch (e) {
      this.handleError(e);
    }
  };

  /**
   * SessionEstimates
   */

  get lastSessionEstimateDate(): DateString | undefined {
    return (
      this.currentEstimate?.session_estimates.toSorted((a, b) => ((a.date ?? '') > (b.date ?? '') ? -1 : 1))[0]?.date ??
      ''
    );
  }

  getSessionEstimateDates = (sessionEstimateId?: TSessionEstimateId): DateString[] => {
    return (this.currentEstimate?.session_estimates
      .filter((se) => se.id !== sessionEstimateId)
      .toSorted((a, b) => ((a.date ?? '') > (b.date ?? '') ? -1 : 1))
      .map((se) => se.date ?? '') ?? []) as DateString[];
  };

  createSessionEstimate = async (title = 'New Estimate', date = new Date().toUTCString(), sessionId?: TSessionId) => {
    if (!this.currentEstimate) {
      console.warn('No currentEstimate - cannot create SessionEstimate');
      return;
    }

    try {
      await SessionEstimateService.createSessionEstimate({
        estimateId: this.currentEstimate.id,
        title,
        date,
        sessionId,
      });

      await this.refreshEstimate(this.currentEstimate.id);
    } catch (e) {
      this.handleError(e);
    }
  };

  updateSessionEstimate = async (
    sessionEstimateId: TSessionEstimateId,
    title?: string,
    date?: Nullable<string>,
    sessionId?: TSessionId
  ) => {
    if (!this.currentEstimate) {
      console.warn('No currentEstimate - cannot update SessionEstimate');
      return;
    }

    try {
      await SessionEstimateService.updateSessionEstimate(sessionEstimateId, {
        title,
        date: date ?? undefined,
        sessionId,
      });

      await this.refreshEstimate(this.currentEstimate.id);
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteSessionEstimate = async (sessionEstimateId: TSessionEstimateId) => {
    if (!this.currentEstimate) {
      console.warn('No currentEstimate - cannot delete SessionEstimate');
      return;
    }

    try {
      await SessionEstimateService.deleteSessionEstimate(sessionEstimateId);

      await this.refreshEstimate(this.currentEstimate.id);
    } catch (e) {
      this.handleError(e);
    }
  };

  /**
   * SessionEstimateItems
   */

  createSessionEstimateItem = async (
    sessionEstimateId: TSessionEstimateId,
    title = 'New Estimate Item',
    units = 1,
    unitType = SessionEstimateItemUnitType.COUNT,
    rate = 0,
    sessionActivityId?: TSessionActivityId
  ) => {
    if (!this.currentEstimate) {
      console.warn('No currentEstimate - cannot create SessionEstimateItem');
      return;
    }

    try {
      await SessionEstimateItemService.createSessionEstimateItem({
        sessionEstimateId,
        sessionActivityId,
        title,
        units,
        unitType,
        rate,
      });

      await this.refreshEstimate(this.currentEstimate.id);
    } catch (e) {
      this.handleError(e);
    }
  };

  updateSessionEstimateItem = async (
    sessionEstimateItemId: TSessionEstimateItemId,
    title?: string,
    units?: number,
    unitType?: SessionEstimateItemUnitType,
    rate?: number,
    taxesMultiplier?: number,
    handlingMultiplier?: number,
    pAndHMultiplier?: number,
    sessionActivityId?: TSessionActivityId
  ) => {
    if (!this.currentEstimate) {
      console.warn('No currentEstimate - cannot update SessionEstimateItem');
      return;
    }

    try {
      await SessionEstimateItemService.updateSessionEstimateItem(sessionEstimateItemId, {
        title,
        units,
        unitType,
        rate,
        taxesMultiplier,
        handlingMultiplier,
        pAndHMultiplier,
        sessionActivityId,
      });

      await this.refreshEstimate(this.currentEstimate.id);
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteSessionEstimateItem = async (sessionEstimateItemId: TSessionEstimateItemId) => {
    try {
      if (!this.currentEstimate) {
        console.warn('No currentEstimate - cannot delete SessionEstimateItem');
        return;
      }

      await SessionEstimateItemService.deleteSessionEstimateItem(sessionEstimateItemId);

      await this.refreshEstimate(this.currentEstimate.id);
    } catch (e) {
      this.handleError(e);
    }
  };

  /**
   * UsageEstimates
   */

  get usageEstimateList(): UsageEstimate[] | undefined {
    return this.currentEstimate?.usage_estimates;
  }

  createUsageEstimate = async (newUsageEstimate: TCreateUsageEstimateRequest) => {
    if (!this.currentEstimate) {
      console.warn('No currentEstimate - cannot create UsageEstimate');
      return;
    }

    try {
      await UsageEstimateService.createUsageEstimate(newUsageEstimate);

      await this.refreshEstimate(this.currentEstimate.id);
    } catch (e) {
      this.handleError(e);
    }
  };

  updateUsageEstimate = async (updatedUsageEstimate: UsageEstimate) => {
    if (!this.currentEstimate) {
      console.warn('No currentEstimate - cannot update UsageEstimate');
      return;
    }

    try {
      await UsageEstimateService.updateUsageEstimate(updatedUsageEstimate.id, updatedUsageEstimate);

      await this.refreshEstimate(this.currentEstimate.id);
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteUsageEstimate = async (usageId: TUsageEstimateId) => {
    if (!this.currentEstimate) {
      console.warn('No currentEstimate - cannot delete UsageEstimate');
      return;
    }

    try {
      await UsageEstimateService.deleteUsageEstimate(usageId);

      await this.refreshEstimate(this.currentEstimate.id);
    } catch (e) {
      this.handleError(e);
    }
  };

  /**
   * UsageEstimateItems
   */

  createUsageEstimateItem = async (newUsageEstimateItem: TCreateUsageEstimateItemRequest) => {
    if (!this.currentEstimate) {
      console.warn('No currentEstimate - cannot create UsageEstimateItem');
      return;
    }

    try {
      await UsageEstimateItemService.createUsageEstimateItem(newUsageEstimateItem);

      await this.refreshEstimate(this.currentEstimate.id);
    } catch (e) {
      this.handleError(e);
    }
  };

  updateUsageEstimateItem = async (
    usageEstimateItemId: TUsageEstimateItemId,
    updatedUsageEstimateItem: TUpdateUsageEstimateItemRequest
  ) => {
    if (!this.currentEstimate) {
      console.warn('No currentEstimate - cannot update UsageEstimateItem');
      return;
    }

    try {
      const { usageEstimateItem } = await UsageEstimateItemService.updateUsageEstimateItem(
        usageEstimateItemId,
        updatedUsageEstimateItem
      );

      await this.refreshEstimate(this.currentEstimate.id);

      return usageEstimateItem;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteUsageEstimateItem = async (usageId: TUsageEstimateItemId) => {
    if (!this.currentEstimate) {
      console.warn('No currentEstimate - cannot delete UsageEstimateItem');
      return;
    }

    try {
      await UsageEstimateItemService.deleteUsageEstimateItem(usageId);

      await this.refreshEstimate(this.currentEstimate.id);
    } catch (e) {
      this.handleError(e);
    }
  };
}
