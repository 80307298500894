import { Run } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Panel } from '..';
import { usePerformerStore, useProjectStore } from '../../../context';
import { PerformerType, Performer } from '/lib/api';


export const PerformerPanel = observer(() => {
  const performerStore = usePerformerStore();
  const projectStore = useProjectStore();
  const [performers, setPerformers] = useState<Performer[]>([]);
  useEffect(() => {
    const projectId = projectStore.currentProject?.id;
    if (!projectId) return;
    const getPerformers = async () => {
      const perfs = await performerStore.listPerformersByProjectId(projectId);
      setPerformers(perfs || []);
    };
    getPerformers();
  }, [projectStore.currentProject?.id]);

  const newPerformer = async () => {
    if (!projectStore.currentProject?.id) return;
    const performerRequest = {
      firstName: 'New',
      lastName: 'Performer',
      type: PerformerType.ON_CAMERA_PRINCIPAL,
      performerType: PerformerType.ON_CAMERA_PRINCIPAL,
      projectId: projectStore.currentProject.id,
    };
    const performer = await performerStore.createPerformer(performerRequest);
    performers && performer && setPerformers([...performers, performer]);
  };

  const deletePerformers = async (ids: string[]) => {
    ids.forEach(async (id) => await performerStore.deletePerformer(id));
    setPerformers(performers.filter((r) => !ids.includes(r.id)));
  };

  const updatePerformer = async (update: Performer, index: number) => {
    const performer = await performerStore.updatePerformer(update);
    if (!performer) return;
    const newPerformers = [...performers];
    newPerformers[index] = performer;
    setPerformers(newPerformers);
  };

  return (
    <Panel
      items={performers.map((p) => ({ ...p, name: p.first_name + ' ' + p.last_name }))}
      newItem={newPerformer}
      deleteItems={deletePerformers}
      updateItem={updatePerformer}
      label="Performer"
      labelPlural="Performers"
      addIcon={<Run />}
      types={[
        {
          label: 'Performer Type',
          key: 'type',
          values: Object.values(PerformerType).filter((v) => !v.includes('Group')),
        },
      ]}
    />
  );
});
