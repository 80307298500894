import { createContext, useContext, ReactNode } from 'react';
import { RootStore } from './stores';

const RootContext = createContext<RootStore | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function useRootStore() {
  const context = useContext(RootContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootContextProvider');
  }
  return context;
}

export function useAccountStore() {
  const { accountStore } = useRootStore();
  if (accountStore === undefined) {
    throw new Error('useAccountStore must be used within RootContextProvider');
  }
  return accountStore;
}

export function useAdminStore() {
  const { adminStore } = useRootStore();
  if (adminStore === undefined) {
    throw new Error('useAdminStore must be used within RootContextProvider');
  }
  return adminStore;
}

export function useEstimateStore() {
  const { estimateStore } = useRootStore();
  if (estimateStore === undefined) {
    throw new Error('useEstimateStore must be used within RootContextProvider');
  }
  return estimateStore;
}

export function useUsageStore() {
  const { usageStore } = useRootStore();
  if (usageStore === undefined) {
    throw new Error('useUsageStore must be used within RootContextProvider');
  }
  return usageStore;
}

export function useUserStore() {
  const { userStore } = useRootStore();
  if (userStore === undefined) {
    throw new Error('useUserStore must be used within RootContextProvider');
  }
  return userStore;
}

export function useProjectStore() {
  const { projectStore } = useRootStore();
  if (projectStore === undefined) {
    throw new Error('useProjectStore must be used within RootContextProvider');
  }
  return projectStore;
}

export function useSessionActivityStore() {
  const { sessionActivityStore } = useRootStore();
  if (sessionActivityStore === undefined) {
    throw new Error('useSessionActivityStore must be used within RootContextProvider');
  }
  return sessionActivityStore;
}

export function useCostumeStore() {
  const { costumeStore } = useRootStore();
  if (costumeStore === undefined) {
    throw new Error('useCostumeStore must be used within RootContextProvider');
  }
  return costumeStore;
}

export function usePropStore() {
  const { propStore } = useRootStore();
  if (propStore === undefined) {
    throw new Error('usePropStore must be used within RootContextProvider');
  }
  return propStore;
}

export function usePerformerStore() {
  const { performerStore } = useRootStore();
  if (performerStore === undefined) {
    throw new Error('useRoleStore must be used within RootContextProvider');
  }
  return performerStore;
}

export function useRoleStore() {
  const { roleStore } = useRootStore();
  if (roleStore === undefined) {
    throw new Error('useRoleStore must be used within RootContextProvider');
  }
  return roleStore;
}

export function useSessionStore() {
  const { sessionStore } = useRootStore();
  if (sessionStore === undefined) {
    throw new Error('useSessionStore must be used within RootContextProvider');
  }
  return sessionStore;
}

export function useShootStore() {
  const { shootStore } = useRootStore();
  if (shootStore === undefined) {
    throw new Error('useShootStore must be used within RootContextProvider');
  }
  return shootStore;
}

export function RootContextProvider({ children }: Props) {
  const store: RootStore = new RootStore();

  return <RootContext.Provider value={store}>{children}</RootContext.Provider>;
}
