import { Accordion, AccordionPanel, Box, Tab, Tabs, Text } from 'grommet';
import { Blank, User as UserIcon, UserExpert } from 'grommet-icons';
import TalonLogo from 'jsx:/public/images/talon-ai-logo.svg';
import { ChatMessage as ChatMessageInterface, MessageType } from '/lib/api';
import { useUserStore } from '/src/context'; 
import { TalonTip } from '../TalonTip';
import { Document as LangchainDocument } from 'langchain/document';
import { useEffect, useState } from 'react';
import { parseIsoDateString } from '/src/utils/time';

export interface IChatMessageProps {
  message: Partial<ChatMessageInterface>;
  index: number;
}

export const ChatMessage = ({ message, index }: IChatMessageProps) => {
  useEffect(() => {
    setMyMsg(message.user_id === userStore.user.id);
    
    switch(message.type) {
      case (MessageType.USER_MESSAGE):
        setBg('light-1');
        setIsSystemMsg(false);
        setIcon(<TalonTip content={<Text>{userStore.user.first_name} {userStore.user.last_name}</Text>}><UserIcon /></TalonTip>);
        break;
      case (MessageType.AI_MESSAGE):
        setBg('white');
        setIcon(<TalonLogo />);
        setIsSystemMsg(false);
        break;
      case (MessageType.SUPPORT_MESSAGE):
        setBg('accent-2');
        setIcon(<TalonTip content={<Text>BA Expert</Text>}><UserExpert /></TalonTip>);
        setIsSystemMsg(false);
        break;
      case (MessageType.SYSTEM_MESSAGE):
        setIsSystemMsg(true);
        setBg('white');
        setIcon(<Blank />);
        break;
      default:
        console.log('Unknown message type:', message.type);
        setBg('white');
        break;
    }
  }, [message]);
  const userStore = useUserStore();

  const [bg, setBg] = useState('');
  const [icon, setIcon] = useState(<TalonTip content={<Text>{userStore.user.first_name} {userStore.user.last_name}</Text>}><UserIcon/></TalonTip>);
  const [myMsg, setMyMsg] = useState(message.user_id === userStore.user.id);
  const [isSystemMsg, setIsSystemMsg] = useState(false);

  return (
    isSystemMsg ? (
      <Box
        key={message.id}
        margin="small"
        align='center'
        justify='center'
      ><Text size='xsmall' color='light-5'>{message.text}</Text>
      </Box>) : (
      <Box
        key={message.id}
        direction={myMsg ? 'row-reverse' : 'row'}
        flex="grow"
        margin="small"
        align='end'
      >
        <Box align="center" justify="end" margin='xsmall' height="40px" width="40px">
          {icon}
        </Box>
        <Box direction="column" background={bg} flex='grow' border={{ color: '#d9d9e3' }} round="small" width={{max: '90%'}}>
          <Box pad="small"  overflow="wrap">
            <Text>
              {message.text?.split('\n').map((line, index) => (
                <Box key={`${line}-${index}`}>
                  {line}
                  <br />
                </Box>
              ))}
            </Text>
            <Box align="start" justify="start">
              <Text size="xsmall" color='light-5'>{message.created_at && parseIsoDateString(message.created_at).time}</Text>
            </Box>
          </Box>
          {!!message.sources?.length && (
            <Accordion key={`sourceDocsAccordion-${index}`}>
              <AccordionPanel label="Sources">
                <Tabs alignControls="start">
                  {message.sources.map((doc: LangchainDocument, docIndex) => (
                    <Tab title={docIndex + 1} key={docIndex}>
                      <Box direction="column" pad="small" gap="small">
                        <Text size="small">
                      (Excerpt from <a
                            href={doc.metadata.url}
                            target='_blank'
                            rel='noreferrer'
                          >{doc.metadata.source}</a>)
                        </Text>
                        {doc.pageContent && <Box pad="small">{doc.pageContent.split('\n').map((line, i) => <Text key={i}>{line}<br/></Text>)}</Box>}
                      </Box>
                    </Tab>
                  ))}
                </Tabs>
              </AccordionPanel>
            </Accordion>
          )}
        </Box>
      </Box>)
  );
};
