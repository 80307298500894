import React from 'react';
import { Box, Form, TextArea, Button, FormExtendedEvent, Stack } from 'grommet';
import { Send } from 'grommet-icons';
import { LoadingDots } from '../LoadingDots';

export interface IUserInputProps {
  handleSubmit: (e: FormExtendedEvent) => void;
  loading: boolean;
  handleEnter: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  query: string;
  setQuery: (query: string) => void;
  gridArea?: string;
}

export const UserInput = ({
  handleSubmit,
  loading,
  handleEnter,
  textAreaRef,
  query,
  setQuery,
  gridArea,
}: IUserInputProps) => (
  <Box gridArea={gridArea} fill="horizontal" pad="small">
    <Form onSubmit={handleSubmit}>
      <Box style={{ position: 'relative' }}>
        <Stack anchor="right">
          <TextArea
            disabled={loading}
            onKeyDown={handleEnter}
            ref={textAreaRef}
            autoFocus={false}
            focusIndicator={false}
            rows={1}
            maxLength={512}
            id="userInput"
            name="userInput"
            placeholder={loading ? 'Waiting for response...' : 'Enter your question here...'}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            fill
          />
          <Box pad={{ horizontal: 'small' }}>
            <Button type="submit" disabled={loading || !query}>
              {loading ? (
                <div>
                  <LoadingDots color="#000" />
                </div>
              ) : (
                <Send />
              )}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Form>
  </Box>
);
