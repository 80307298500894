import React from 'react';
import { Anchor, Box, CheckBox, Layer, FileInput, Spinner, Stack, Text } from 'grommet';
import { Close, DocumentText, DocumentPerformance, DocumentTime, DocumentUpload, DocumentVideo } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Selector, TalonCard } from '..';
import { useProjectStore } from '../../../context';
import { Document, DocumentType } from '/lib/api';
import { CurrentDocument } from '.';

export const DocumentsPanel = observer(() => {
  const projectStore = useProjectStore();
  const [documents, setDocuments] = useState<Document[]>(projectStore.currentProject?.documents || []);
  const [currentDocument, setCurrentDocument] = useState<Document | undefined>(undefined);
  const [acceptable, setAcceptable] = useState<string>('');
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>('');
  const [selected, setSelected] = useState<string[]>([]);

  const EXCEL = '.xls,.xlsx,.csv';
  const WORD = '.doc,.docx';
  const PDF = '.pdf';
  // const PPT = '.ppt,.pptx';

  interface IDocConfig {
    [key: string]: {
      icon: JSX.Element;
      acceptable: string;
    };
  }
  const documentConfig: IDocConfig = {
    [DocumentType.SCRIPT]: {
      icon: <DocumentText />,
      acceptable: [WORD, PDF].join(','),
    },
    [DocumentType.MEDIA_PLAN]: {
      icon: <DocumentPerformance />,
      acceptable: EXCEL,
    },
    [DocumentType.SHOOT_SCHEDULE]: {
      icon: <DocumentTime />,
      acceptable: EXCEL,
    },
    [DocumentType.STORYBOARD]: {
      icon: <DocumentVideo />,
      acceptable: PDF,
    },
  };

  useEffect(() => {
    const projectId = projectStore.currentProject?.id;
    if (!projectId) return;
    setDocuments(projectStore.currentProject?.documents || []);
  }, [projectStore.currentProject?.id, projectStore.currentProject?.documents]);

  const doUpload = async (e?: React.ChangeEvent<HTMLInputElement>) => {
    const files = e?.target?.files;
    if (!files?.[0] || !fileType) return;

    setLoading(true);
    const doc = await projectStore.createDocument(files[0], fileType);
    if (!doc) {
      toast.error('Error uploading document');
    }
    setLoading(false);
    setShowUpload(false);
  };

  const deleteDocuments = async () => {
    if (!selected.length) return;
    for (const id of selected) {
      await projectStore.deleteDocument(id);
    }
    setDocuments(documents.filter((l) => !selected.includes(l.id)));
    setCurrentDocument(undefined);
    setSelected([]);
  };

  const updateDocument = async (update: Document) => {
    const document = await projectStore.updateDocument(update.id, update);
    if (!document) return;
    const newDocuments = documents.map((d) => (d.id === document.id ? document : d));
    setDocuments(newDocuments);
  };

  const chooseUploadFileType = (type: string) => {
    setAcceptable(documentConfig[type].acceptable);
    setFileType(type);
  };

  const newDoc = () => {
    setShowUpload(true);
  };

  const choose = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
      setCurrentDocument(undefined);
    } else {
      setSelected([...selected, id]);
      setCurrentDocument(documents.find((d) => d.id === id));
    }
  };
  const selectedNames = () => {
    return selected.map((s) => documents.find((item) => item.id === s)?.name).join(', ');
  };

  const getIcon = (doc: Document) => {
    const icon = React.cloneElement(documentConfig[doc.type].icon, { size: 'large', a11yTitle: doc.type });
    return <Anchor onClick={() => setCurrentDocument(doc)}>{icon}</Anchor>;
  };

  return (
    <TalonCard
      newItem={newDoc}
      deleteItems={deleteDocuments}
      label="Document"
      labelPlural="Documents"
      addIcon={<DocumentUpload />}
      selectedNames={selectedNames()}
      // height={{ min: '80vh' }}
    >
      {showUpload && (
        <Layer
          onEsc={() => setShowUpload(false)}
          onClickOutside={() => setShowUpload(false)}
          position="center"
          modal={true}
          margin="large"
        >
          <Box direction="column" align="center" justify="around" width="medium" height="medium" background="light-1">
            <Box direction="row" justify="end" width="100%" pad="small">
              <Close name="Close" onClick={() => setShowUpload(false)} style={{ cursor: 'pointer' }} />
            </Box>
            <Text>Upload a new document</Text>
            {!loading && (
              <Box direction="column" align="center" fill={true} justify="around" pad="small" margin="small">
                <Selector
                  options={Object.keys(documentConfig).map((key) => ({ name: key, id: key }))}
                  onChange={(value) => chooseUploadFileType(value)}
                  placehholder="Choose document type..."
                  size="medium"
                  clear={true}
                />
                <FileInput name="file" onChange={(e) => doUpload(e)} accept={acceptable} disabled={!acceptable} />
                <Text>
                  {acceptable && 'Acceptable file types: '}
                  {acceptable}
                </Text>
              </Box>
            )}
            {loading && <Spinner size="large" background="light-1" />}
          </Box>
        </Layer>
      )}
      <Box direction="row" overflow={{ horizontal: 'auto' }} wrap={true}>
        {documents.map((doc) => (
          <Box direction="column" justify="between" pad="small" align="center" key={doc.id}>
            <Stack anchor="top-left">
              <Box pad={{ left: 'medium' }}>{getIcon(doc)}</Box>
              <CheckBox checked={selected.includes(doc.id)} onChange={() => choose(doc.id)} />
            </Stack>

            <Text truncate="tip" size="small">
              {doc.name}
            </Text>
          </Box>
        ))}
      </Box>
      {currentDocument && (
        <CurrentDocument doc={currentDocument} update={updateDocument} close={() => setCurrentDocument(undefined)} />
      )}
    </TalonCard>
  );
});
