import { Box, Nav } from 'grommet';
import { DocumentText, Home, Resources, Table, Video } from 'grommet-icons';
import { FavoriteFilled } from '../ui/icons/FilledIcons';
import TalonLogo from 'jsx:/public/images/talon-ai-logo.svg';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Selector } from '..';
import { AnchorLink } from './AnchorLink';
import { UserMenu } from './UserMenu';
import { useProjectStore, useUserStore } from '/src/context';
import { Project } from '../../../lib/api';

export const HeaderNav = observer(() => {
  const projectStore = useProjectStore();
  const userStore = useUserStore();
  const [projectList, setProjectList] = useState(projectStore.projectList);
  const [favorites, setFavorites] = useState<string[]>([]);
  useEffect(() => {
    const getProjects = async () => {
      await projectStore.fetchProjectList();
    };
    getProjects();
  }, [projectStore]);

  useEffect(() => {
    const favs = projectStore.projectList?.filter((p) => userStore.user?.favorite_project_ids?.includes(p.id));
    favs?.sort((a, b) => a.name.localeCompare(b.name));
    const notFavs = projectStore.projectList?.filter((p) => !userStore.user?.favorite_project_ids?.includes(p.id));
    notFavs?.sort((a, b) => a.name.localeCompare(b.name));
    setProjectList([...favs || [], ...notFavs || []]);
  }, [projectStore.projectList, userStore.user?.favorite_project_ids]);

  useEffect(() => {
    setFavorites(userStore.user?.favorite_project_ids || []);
  }, [userStore.user?.favorite_project_ids]);

  return (
    <Nav direction="row" background="brand" flex="grow" justify="evenly" pad="small">
      <AnchorLink
        to="/"
        label="TALON AI"
        icon={<TalonLogo width="40" height="40" color="white" />}
        alignSelf="center"
      />
      {projectStore.currentProject?.id && (
        <Box alignSelf="center">
          <Selector
            options={projectList}
            value={projectStore.currentProject.id}
            onChange={(val) => projectStore.setCurrentProjectId(val)}
            labelKey={(p: Project) => (favorites.includes(p.id) ? <Box direction="row" pad="small" align='center'><FavoriteFilled filled size="small"  />&nbsp; {p.name}</Box> : <Box pad="small">{p.name}</Box>)}
          />
        </Box>
      )}
      <AnchorLink
        to={projectStore.currentProject?.id ? '/project' : '#'}
        label="Project Home"
        icon={<Home />}
        alignSelf="center"
        disabled={!projectStore.currentProject?.id}
      />

      <AnchorLink
        to={projectStore.currentProject?.id ? '/shoot-planner' : '#'}
        label="Shoot Planner"
        icon={<Video />}
        alignSelf="center"
        disabled={!projectStore.currentProject?.id}
      />
      <AnchorLink
        to={projectStore.currentProject?.id ? '/estimate' : '#'}
        label="Estimate"
        icon={<Table />}
        alignSelf="center"
        disabled={!projectStore.currentProject?.id}
      />
      <AnchorLink
        to={projectStore.currentProject?.id ? '/contracting' : '#'}
        label="Contracting"
        icon={<DocumentText />}
        alignSelf="center"
        disabled={!projectStore.currentProject?.id}
      />
      <AnchorLink
        to={'/resources'}
        label="Resources"
        icon={<Resources />}
        alignSelf="center"
      />
      <Box direction="column" alignSelf="center" justify="center">
        <UserMenu />
      </Box>
    </Nav>
  );
});
