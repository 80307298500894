import { Box, Layer, Tab, Tabs, Text } from 'grommet';
import { observer } from 'mobx-react-lite';
import { Channel, Close, Deploy, Globe, Multimedia, Run } from 'grommet-icons';
import { useState, useEffect } from 'react';

import { useUserStore, useShootStore, useSessionStore, usePerformerStore} from '/src/context';
import { toast } from 'react-toastify';
import { ProjectWizardFooter } from './ProjectWizardFooter';

import { CommercialType, LocationType, PerformerType } from '/lib/api';
import { useProjectStore } from '../../../context';
import { StartTab, GeographyTab, MediaTab, AssetsTab, PerformersTab, SummaryTab } from '.';
import { IAddress } from '../../../utils/decodeGoogle';
import { Loader } from '..';

const STEPS = 5;

export type IMediaAssetLengthMap = {
  [K in keyof typeof CommercialType]: number[];
};

export type IPerformerCountMap = {
  [K in keyof typeof PerformerType]: number;
};

export const ProjectWizard = observer(({close}: {close: () => void}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [showNext, setShowNext] = useState(true);
  const [showPrevious, setShowPrevious] = useState(false);

  const [union, setUnion] = useState<string | undefined>();
  const [geography, setGeography] = useState<string | undefined>();
  const [place, setPlace] = useState<IAddress | undefined>();
  const [locationType, setLocationType] = useState<LocationType | undefined>();
  const [firstSessionDate, setFirstSessionDate] = useState<string>(new Date().toISOString());
  
  const userStore = useUserStore();
  const [code, setCode] = useState<string>('');
  const [media, setMedia] = useState<CommercialType[]>();
  const [assetCounts, setAssetCounts] = useState<IMediaAssetLengthMap>({} as IMediaAssetLengthMap);
  const [performerCounts, setPerformerCounts] = useState<IPerformerCountMap>({} as IPerformerCountMap);
  const mediaOptions = Object.keys(CommercialType).map((key) => CommercialType[key as keyof typeof CommercialType]);
  const [exclusivity, setExclusivity] = useState<string | undefined>();
  const [clientList, setClientList] = useState<string[]>([]);
  const [name, setName] = useState<string>('');
  const [client, setClient] = useState<string>('');
  const [product, setProduct] = useState<string>('');
  const [budget, setBudget] = useState<number>(0);
  const [showLoader, setShowLoader] = useState(false);
  const projectStore = useProjectStore();
  const shootStore = useShootStore();
  const sessionStore = useSessionStore();
  const performerStore = usePerformerStore();

  useEffect(() => { 
    if(projectStore.clientList.length === 0) return;
    setClientList(projectStore.clientList);
  }, [projectStore.clientList]);

  const createProject = async () => {
    if (!name || !client || !product) {
      toast.error('Please fill out all fields');
      return;
    }
    if (!userStore.user.active_group) {
      toast.error('An internal error has occurred. Please refresh the page and try again.');
      return;
    }
    setShowLoader(true);
    const project = await projectStore.createProject({
      name,
      groupId: userStore.user.active_group?.id,
      client,
      product,
      jobCode: code,
      is_union: union === 'union',
      budget,
    });

    const locationRequest = {
      name: `${name} Shoot Location`,
      type: locationType || LocationType.NEARBY,
      street_address: place?.street_address || '',
      aptOrUnit: place?.aptOrUnit || '',
      city: place?.city || '',
      state: place?.state || '',
      zip: place?.zip || '',
      projectId: project.id,
    };
    try {
      place && await projectStore.createLocation(locationRequest);
      const shoot = await shootStore.createShoot(project.id, `${name} Shoot`);
      if (!shoot) {
        throw Error('Shoot creation failed');
      }
      const script = await projectStore.createScript(project.id, `${name} Script`);
      firstSessionDate && await sessionStore.createSession(new Date(firstSessionDate).toISOString(), shoot.id, script.id);
      for (const medium in assetCounts) {
        for (const length of assetCounts[medium as keyof typeof CommercialType]) {
          await shootStore.createCommercial({shootId: shoot.id, title: `${length} asset`, type: medium as CommercialType, durationInSeconds: length, jobCode: code });
        }
      }
      for (const performerType in performerCounts) {
        for (let i = 0; i < performerCounts[performerType as keyof typeof PerformerType]; i++) {
          await performerStore.createPerformer({projectId: project.id, type: PerformerType[performerType as keyof typeof PerformerType], firstName:`Performer ${i + 1}`});
        }
      }
    } catch (e) {
      toast.error(`An internal error has occurred: ${e}`);
    }
    setClient('');
    setName('');
    setProduct('');
    setCode('');
    setUnion(undefined);
    setBudget(0);
    setGeography(undefined);
    setLocationType(undefined);
    setPlace(undefined);
    setFirstSessionDate(new Date().toISOString());
    setExclusivity(undefined);
    setMedia(undefined);
    setAssetCounts({} as IMediaAssetLengthMap);
    setPerformerCounts({} as IPerformerCountMap);
    setActiveSlide(0);
    setShowLoader(false);
    close();
  };

  const valid = () => {
    return (name && client && product ? true : false);
  };

  const onChild = (index: number) => {
    if (index < 0 || index > STEPS) {
      return;
    }
    if (index === 0) {
      setShowPrevious(false);
    } else {
      setShowPrevious(true);
    }
    if (index === STEPS) {
      setShowNext(false);
    } else {
      setShowNext(true);
    }
    setActiveSlide(index);
  };

  return (
    <Layer>
      {showLoader ? <Loader /> : <Box width='large' direction="column" elevation='medium'>
        <Box pad="small" direction="row" background="brand" align="center" justify="between">
          <Box direction="row"><Text>Pre-pro Talent Wizard</Text></Box>
          <Close onClick={close} style={{cursor: 'pointer'}}/>
        </Box>
        <Box overflow={{ horizontal: 'hidden', vertical: 'scroll' }} height='large'>
          <Tabs activeIndex={activeSlide} onActive={onChild}>
            <Tab title={<Text size='small'>Start</Text>}>
              <StartTab name={name} code={code} client={client} product={product} budget={budget} clientList={clientList} setName={setName} setCode={setCode} setClient={setClient} setProduct={setProduct} setBudget={setBudget} />
            </Tab>
            <Tab icon={<Globe />} title={<Text size='small'>Geography</Text>} disabled={!valid()}>
              <GeographyTab geography={geography} place={place} setPlace={setPlace} union={union} firstSessionDate={firstSessionDate} locationType={locationType} setFirstSessionDate={setFirstSessionDate} setGeography={setGeography} setUnion={setUnion} setLocationType={setLocationType} />
            </Tab>
            <Tab icon={<Multimedia />} title={<Text size='small'>Media</Text>} disabled={!valid()}>
              <MediaTab media={media} setMedia={setMedia} mediaOptions={mediaOptions} exclusivity={exclusivity} setExclusivity={setExclusivity} />
            </Tab>
            <Tab icon={<Channel />} title={<Text size='small'>Assets</Text>} disabled={!valid()}>
              <AssetsTab media={media} setAssetCounts={setAssetCounts} assetCounts={assetCounts} />
            </Tab>
            <Tab icon={<Run />} title={<Text size='small'>Performers</Text>} disabled={!valid()}>
              <PerformersTab setPeformerCounts={setPerformerCounts} performerCounts={performerCounts} />
            </Tab>
            <Tab icon={<Deploy />} title={<Text size='small'>Summary</Text>} disabled={!valid()}>
              <SummaryTab 
                name={name} 
                code={code} 
                client={client}
                product={product}
                budget={budget}
                union={union}
                geography={geography}
                place={place}
                media={media}
                exclusivity={exclusivity}
                assetCounts={assetCounts}
                performerCounts={performerCounts}
              />
            </Tab>
          </Tabs>
        </Box>
        <Box direction="column" justify="stretch" fill="horizontal" background={{color: 'light-4'}} pad="small">
          <ProjectWizardFooter activeSlide={activeSlide} setActiveSlide={onChild} previous={showPrevious} next={showNext} createFn={createProject} valid={valid}/>
        </Box>
      </Box>}
    </Layer>
  );
});
