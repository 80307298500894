/**
 * Grommet Theme
 *
 * https://v2.grommet.io/grommet
 */

const grommetTheme = {
  global: {
    colors: {
      brand: '#25094b',
      'accent-1': '#ffffff',
      'accent-2': '#ed9ac2',
      'accent-3': '#a559a3',
      'accent-4': '#4c489e',
      // light purple background color
      'hint-background': '#DAB8F7FF',
      alert: 'red',
    },
    focus: {
      outline: {
        size: '0px',
      },
    },
  },
  accordion: {
    border: undefined,
  },
  tip: {
    content: {
      background: {
        color: 'light-1',
        opacity: '100%',
      }
    }
  },
  tab: {
    // active: {
    //   background: 'background-contrast',
    // },
    // hover: {
    //   background: 'accent-2',
    // },
    // margin: 'small',
    // pad: 'small',
    color: 'text-strong',
    active: {
      background: 'background',
    },
    background: 'background-contrast',
    border: {
      side: 'bottom',
      color: 'border',
      active: {
        color: 'border-strong',
      },
      disabled: {
        color: 'border-weak',
      },
      hover: {
        color: 'border',
      },
    },
    disabled: {
      color: 'text-weak',
    },
    hover: {
      background: 'accent-2',
      color: 'text',
    },
    pad: 'small',
    margin: {
      horizontal: 'none',
    },

  },
  tabs: {
    gap: 'small',
  },
  textArea: {
    extend: () => `
    resize: none;
    font-size: 1.1rem;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 0.5rem;
    border: 1px solid #d9d9e3;
    background: #ffffff;
    color: #000;
    outline: none;  
    `,
  },
  formField: {
    label: {
      requiredIndicator: true,
    },
  },
};

export default grommetTheme;
