import { FloatString, IntString, Nullable } from './commonTypes';
import {
  PerformerType,
  SessionEstimateItemUnitType,
  TCommercialId,
  TRoleId,
  Usage,
  UsageCycleType,
  UsageType,
  UsageUnitType,
} from '/lib/api';

/**
 * Session Estimates
 */

export type TSessionEstimateForm = {
  title: string;
  date: Nullable<Date>;
};

export type TSessionEstimateItemForm = {
  title: string;
  units: FloatString;
  unitType: SessionEstimateItemUnitType;
  rate: FloatString;
  taxesMultiplier: FloatString;
  handlingMultiplier: FloatString;
  pAndHMultiplier: FloatString;
  [key: string]: string | FloatString | SessionEstimateItemUnitType;
};

/**
 * Usage Estimates
 */

export type TUsageEstimateForm = {
  title: string;
};

export type TUsageEstimateItemForm = {
  title: string;
  roleId: TRoleId;
  performerType?: PerformerType;
  type?: UsageType;
  units: FloatString;
  unitType?: UsageUnitType;
  cycles: IntString;
  cycleType?: UsageCycleType;
  rate: FloatString;
  taxesMultiplier: FloatString;
  handlingMultiplier: FloatString;
  pAndHMultiplier: FloatString;
  [key: string]: string | FloatString | IntString | UsageUnitType | Usage | TCommercialId | TRoleId | undefined;
};
