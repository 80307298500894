/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type
 */
export enum CommercialType {
    TV = 'TV',
    RADIO = 'Radio',
    DIGITAL = 'Digital',
    PRINT = 'Print',
    OOH = 'OOH',
    SOCIAL = 'Social',
    BTS = 'BTS',
    OTHER = 'Other',
}
