import { MapLocation } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Panel } from '..';
import { useProjectStore } from '../../../context';
import { Location, LocationType, StudioZone } from '/lib/api';
import { Location as LocationComponent } from '.';

export const LocationPanel = observer(() => {
  const [locations, setLocations] = useState<Location[]>([]);
  const projectStore = useProjectStore();

  useEffect(() => {
    const projectId = projectStore.currentProject?.id;
    if (!projectId) return;
    const getLocations = async () => {
      const locs = await projectStore.getLocationsByProjectId(projectId);
      setLocations(locs || []);
    };
    getLocations();
  }, [projectStore.currentProject?.id]);

  const newLocation = async () => {
    if (!projectStore.currentProject?.id) return;
    const locationRequest = {
      name: 'New Location',
      type: LocationType.NEARBY,
      street_address: '',
      aptOrUnit: '',
      city: '',
      state: '',
      zip: '',
      projectId: projectStore.currentProject.id,
    };
    const location = await projectStore.createLocation(locationRequest);
    if (!location) return;
    setLocations([...locations, location]);
  };

  const deleteLocations = async (ids: string[]) => {
    for (const id of ids) {
      await projectStore.deleteLocation(id);
    }
    setLocations(locations.filter((l) => !ids.includes(l.id)));
  };

  const updateLocation = async (update: Location, index: number) => {
    const location = await projectStore.updateLocation(update);
    if (!location) return;
    const newLocations = [...locations];
    newLocations[index] = location;
    setLocations(newLocations);
  };


  return (
    <Panel
      items={locations}
      newItem={newLocation}
      deleteItems={deleteLocations}
      updateItem={updateLocation}
      label="Location"
      labelPlural="Locations"
      addIcon={<MapLocation />}
      types={[
        {
          label: 'Location Type',
          key: 'type',
          values: Object.values(LocationType),
        },
        {
          label: 'Studio Zone',
          key: 'studio_zone',
          values: Object.values(StudioZone),
          readonly: true,
        },
      ]}
    >
      {locations.map(({session_activities, project, ...location}, i) => (
        <LocationComponent 
          place={location} 
          id={location.id}
          updatePlace={(p) =>  updateLocation({ ...locations[i], session_activities, project, ...p }, i)} key={location.id} />
      ))}  
    </Panel>
  );
});
