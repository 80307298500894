import { Box, Form, FormField, Text, TextInput } from 'grommet';
import { CreateSelect } from '../../ui';
import { CurrencyInput } from './CurrencyInput';
import { Hint } from '.';

interface IStartTabProps {
    name: string;
    code: string;
    client: string;
    product: string;
    budget: number;
    clientList: string[];
    setName: (value: string) => void;
    setCode: (value: string) => void;
    setClient: (value: string) => void;
    setProduct: (value: string) => void;
    setBudget: (value: number) => void;
}


export const StartTab = (props: IStartTabProps) => {

  const {name, code, client, product, budget, clientList, setName, setCode, setClient, setProduct, setBudget } = props;


  return (
    <Box pad="medium" direction='column' width='100%' gap='small'>
      <Box>
        <Text>Welcome! Let's get started creating a new project.  Fill in as much as you can, and don't worry if you don't know some answers right now.  You can always make changes later. (This is the only tab in the wizard with required* fields.)</Text>
      </Box>
      <Box direction='row'>
        <Box direction='column' >
          <Form validate='blur' id='start'>
            <Box direction="row">
              <FormField label="Project Name" htmlFor="project-name" validate={[(val: string) => val.length > 3 ? true : undefined] } name='project-name' required>
                <TextInput
                  id="project-name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  width={{'max': 'medium'}}
                  aria-required
                  name='project-name'
                />
              </FormField>
            </Box>
            <Box direction="row">
              <FormField label="Job Code" htmlFor="job-code" required name='job-code'>
                <TextInput id="job-code" value={code} onChange={(e) => setCode(e.target.value)} width={{max: 'medium'}} name='job-code'/>
              </FormField>
            </Box>
            <Box direction="row" pad={{vertical: 'medium'}}>
              <FormField label="Client" htmlFor="select-client" name='client' required>
                <CreateSelect
                  id="select-client"
                  defaultOptions={clientList}
                  onChange={setClient}
                  defaultValue={client}
                  required
                  placeholder='Select Client'
                  name='client'
                />
              </FormField>
            </Box>
            <Box direction="row">
              <FormField label="Product" htmlFor="product" required name='product'>
                <TextInput
                  id="product"
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                  width={{'max': 'medium'}}
                  name='product'
                />
              </FormField>
            </Box>
            <Box direction="column" gap="small" width="medium">
              <Box>
                <CurrencyInput value={budget} onChange={setBudget} />
              </Box>
            </Box>
          </Form>
        </Box>
        <Box width='50%'>
          { budget > 1000000 ? (
            <Hint>
              <Text>Heads up! This budget is over $1,000,000, so the Low Budget Waiver will not apply.</Text>
            </Hint>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};