import { makeObservable, observable, runInAction } from 'mobx';
import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import { TCreateUsageRequest, TUpdateUsageRequest, TUsageId, Usage, UsageService } from '/lib/api';

export class UsageStore extends BaseStore {
  currentUsage?: Usage;
  usageList: Usage[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      currentUsage: observable,
      usageList: observable,
    });
  }

  setCurrentUsage(usage?: Usage) {
    runInAction(() => (this.currentUsage = usage));
  }

  setUsageList(usageList: Usage[]) {
    runInAction(() => (this.usageList = usageList));
  }

  listUsages = async (relations = 'usage_estimate') => {
    try {
      const { usages } = await UsageService.listUsages(undefined, undefined, undefined, relations);
      this.setUsageList(usages);
      return usages;
    } catch (e) {
      this.handleError(e);
    }
  };

  getUsage = async (usageId: TUsageId, relations = 'usage_estimate') => {
    try {
      const { usage } = await UsageService.getUsage(usageId, relations);
      return usage;
    } catch (e) {
      this.handleError(e);
    }
  };

  createUsage = async (usageData: TCreateUsageRequest) => {
    try {
      const { usage } = await UsageService.createUsage(usageData);
      const newUsage = await this.getUsage(usage?.id, 'usage_estimate');
      if (newUsage) {
        runInAction(() => {
          this.usageList.push(newUsage);
        });
        return newUsage;
      }
    } catch (e) {
      this.handleError(e);
    }
  };

  updateUsage = async (usageId: TUsageId, usageData: TUpdateUsageRequest) => {
    try {
      await UsageService.updateUsage(usageId, usageData);
      const updatedUsage = await this.getUsage(usageId, 'usage_estimate,roles');
      if (updatedUsage) {
        runInAction(() => {
          const i = this.usageList?.findIndex(({ id }) => id === usageId) ?? -1;
          if (i > -1) {
            this.usageList.splice(i, 1, updatedUsage);
          } else {
            this.usageList.push(updatedUsage);
          }
        });
        return updatedUsage;
      }
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteUsage = async (usageId: TUsageId) => {
    try {
      await UsageService.deleteUsage(usageId);
      if (usageId === this.currentUsage?.id) this.setCurrentUsage(undefined);
      runInAction(() => {
        this.usageList = this.usageList?.filter((p) => p.id !== usageId);
      });
    } catch (e) {
      this.handleError(e);
    }
  };
}
