import { useRef, useState } from 'react';
import { useAutocomplete } from '@vis.gl/react-google-maps';
import { getAddressObject } from '/src/utils/decodeGoogle';
import { TextInput } from 'grommet';

export interface IGoogleMapAutoComplete {
  defaultValue?: string;
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
  options?: google.maps.places.AutocompleteOptions;
  placeholder?: string;
}

export const GoogleMapAutoComplete = ({
  defaultValue,
  onPlaceSelected,
  options,
  placeholder,
}: IGoogleMapAutoComplete) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(defaultValue);

  const onPlaceChanged = (place: google.maps.places.PlaceResult) => {
    if (place && place.address_components) {
      const address = getAddressObject(place.address_components);
      setInputValue(`${address.street_address} ${address.city} ${address.state} ${address.zip}`);
    }
    onPlaceSelected(place);
    // Keep focus on input element
    inputRef.current && inputRef.current.focus();
  };

  useAutocomplete({
    inputField: inputRef && inputRef.current,
    onPlaceChanged,
    options,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return <TextInput size='small' ref={inputRef} value={inputValue} onChange={handleInputChange} placeholder={placeholder} />;
};
