import { useEffect, useState } from 'react';
import { Box, Select } from 'grommet';

export interface IOptions {
  id: string;
  name: string;
}
export interface ISelectorProps<T> {
  options?: Array<IOptions>;
  value?: string;
  onChange: (value: string) => void;
  placehholder?: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge' | string;
  clear?: boolean;
  labelKey?: string | ((value: T) => React.ReactNode);
}
export const Selector = <T,>({
  options = [],
  value = '',
  onChange,
  placehholder = 'Select...',
  size = 'medium',
  clear = false,
  labelKey = 'name',
}: ISelectorProps<T>) => {
  const [val, setVal] = useState(value);
  const [opts, setOpts] = useState(options);

  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    setOpts(options);
  }, [options]);

  const update = (newVal: string) => {
    setVal(newVal);
    onChange(newVal);
  };

  return (
    <Box>
      <Select
        size={size}
        placeholder={placehholder}
        onClose={() => setOpts(options || [])}
        onSearch={(text) => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

          // Create the regular expression with modified value which
          // handles escaping special characters. Without escaping special
          // characters, errors will appear in the console
          const exp = new RegExp(escapedText, 'i');
          setOpts(options.filter((o) => exp.test(o.name)));
        }}
        labelKey={labelKey}
        valueKey={{ key: 'id', reduce: true }}
        value={val}
        options={opts}
        onChange={({ value: nextValue }) => update(nextValue)}
        clear={clear}
        plain
      />
    </Box>
  );
};
