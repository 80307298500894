import { Box, Button, Text } from 'grommet';
import { Trash } from 'grommet-icons';
import { capitalize } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { SessionEstimateItem, SessionEstimateItemUnitType } from '/lib/api';
import { DeleteModal, EditableTextV2, SelectableText } from '/src/components';
import { useEstimateStore } from '/src/context';
import { TSessionEstimateItemForm } from '/src/types';
import { USD } from '/src/utils/currency';

export interface ISessionEstimateDataRowProps {
  sessionEstimateItem: SessionEstimateItem;
  index: number;
  useColors?: boolean;
}

export const SessionEstimateDataRow = observer(
  ({ sessionEstimateItem, index, useColors }: ISessionEstimateDataRowProps) => {
    /** Refs **/
    const defaultFormData = useRef<TSessionEstimateItemForm>({
      title: 'New Estimate Item',
      units: '1',
      unitType: SessionEstimateItemUnitType.COUNT,
      rate: '0',
      taxesMultiplier: '0',
      handlingMultiplier: '0',
      pAndHMultiplier: '0',
    });

    /** Context **/
    const estimateStore = useEstimateStore();

    /** State **/
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [formData, setFormData] = useState<TSessionEstimateItemForm>({
      title: sessionEstimateItem.title,
      units: sessionEstimateItem.units?.toString() ?? defaultFormData.current.units,
      unitType: sessionEstimateItem.unit_type ?? (defaultFormData.current.unit_type as SessionEstimateItemUnitType),
      rate: sessionEstimateItem.rate?.toString() ?? defaultFormData.current.rate,
      taxesMultiplier: sessionEstimateItem.taxes_multiplier
        ? (sessionEstimateItem.taxes_multiplier * 100).toString()
        : defaultFormData.current.taxesMultiplier,
      handlingMultiplier: sessionEstimateItem.handling_multiplier
        ? (sessionEstimateItem.handling_multiplier * 100).toString()
        : defaultFormData.current.handlingMultiplier,
      pAndHMultiplier: sessionEstimateItem.p_and_h_multiplier
        ? (sessionEstimateItem.p_and_h_multiplier * 100).toString()
        : defaultFormData.current.pAndHMultiplier,
    });

    /** Computed **/
    const canEdit = estimateStore.canEditCurrent;

    /** Methods **/
    const updateEstimateItem = async (data: TSessionEstimateItemForm) => {
      const { title, units, unitType, rate, taxesMultiplier, handlingMultiplier, pAndHMultiplier } = data;

      await estimateStore.updateSessionEstimateItem(
        sessionEstimateItem.id,
        title,
        parseInt(units || '0'),
        unitType as SessionEstimateItemUnitType,
        parseFloat(rate || '0'),
        parseFloat(taxesMultiplier || '0') / 100,
        parseFloat(handlingMultiplier || '0') / 100,
        parseFloat(pAndHMultiplier || '0') / 100
      );
    };

    const deleteEstimateItem = async () => {
      setShowDeleteModal(false);
      await estimateStore.deleteSessionEstimateItem(sessionEstimateItem.id);
    };

    /** Render **/
    return (
      <Box>
        <Box
          direction="row"
          gap="medium"
          pad="medium"
          align="center"
          background={useColors && !(index % 2) ? 'light-1' : 'white'}
        >
          <Box fill="horizontal">
            {canEdit ? (
              <EditableTextV2
                val={formData.title}
                onChange={(title) => setFormData({ ...formData, title })}
                onBlur={() => updateEstimateItem(formData)}
                onSubmit={() => updateEstimateItem(formData)}
              />
            ) : (
              <Text>{formData.title}</Text>
            )}
          </Box>

          <Box width="75%" direction="row" gap="small" align="center">
            <Box>
              {canEdit ? (
                <EditableTextV2
                  val={formData.units}
                  onChange={(units) => {
                    const data = { ...formData, units };
                    setFormData(data);
                    updateEstimateItem(data);
                  }}
                  onBlur={() => updateEstimateItem(formData)}
                  onSubmit={() => updateEstimateItem(formData)}
                  minValue={1}
                  inputType="number"
                />
              ) : (
                <Text>{formData.units}</Text>
              )}
            </Box>
            <Box>
              {canEdit ? (
                <SelectableText
                  val={formData.unitType}
                  onChange={(unitType) => {
                    const data = {
                      ...formData,
                      unitType: unitType as SessionEstimateItemUnitType,
                    };
                    setFormData(data);
                    updateEstimateItem(data);
                  }}
                  onBlur={() => updateEstimateItem(formData)}
                  onSubmit={() => updateEstimateItem(formData)}
                  options={Object.values(SessionEstimateItemUnitType).map((unitType) => ({
                    label: capitalize(unitType),
                    value: unitType,
                  }))}
                  capitalize
                />
              ) : (
                <Text>{capitalize(formData.unitType)}</Text>
              )}
            </Box>
          </Box>
          <Box width="55%">
            {canEdit ? (
              <EditableTextV2
                val={formData.rate}
                onChange={(rate) => {
                  const data = { ...formData, rate };
                  setFormData(data);
                  updateEstimateItem(data);
                }}
                onBlur={() => updateEstimateItem(formData)}
                onSubmit={() => updateEstimateItem(formData)}
                inputType="number"
                isCurrency
              />
            ) : (
              <Text>{USD(formData.rate)}</Text>
            )}
          </Box>
          <Box width="60%">
            <Text weight={600}>{USD(sessionEstimateItem?.subtotal)}</Text>
          </Box>
          <Box width="40%">
            {canEdit ? (
              <EditableTextV2
                val={formData.pAndHMultiplier}
                onChange={(pAndHMultiplier) => {
                  const data = { ...formData, pAndHMultiplier };
                  setFormData(data);
                  updateEstimateItem(data);
                }}
                onBlur={() => updateEstimateItem(formData)}
                onSubmit={() => updateEstimateItem(formData)}
                inputType="number"
                isPercent
              />
            ) : (
              <Text>{formData.pAndHMultiplier}%</Text>
            )}
          </Box>
          <Box width="40%">
            {canEdit ? (
              <EditableTextV2
                val={formData.handlingMultiplier}
                onChange={(handlingMultiplier) => {
                  const data = { ...formData, handlingMultiplier };
                  setFormData(data);
                  updateEstimateItem(data);
                }}
                onBlur={() => updateEstimateItem(formData)}
                onSubmit={() => updateEstimateItem(formData)}
                inputType="number"
                isPercent
              />
            ) : (
              <Text>{formData.handlingMultiplier}%</Text>
            )}
          </Box>
          <Box width="40%">
            {canEdit ? (
              <EditableTextV2
                val={formData.taxesMultiplier}
                onChange={(taxesMultiplier) => {
                  const data = { ...formData, taxesMultiplier };
                  setFormData(data);
                  updateEstimateItem(data);
                }}
                onBlur={() => updateEstimateItem(formData)}
                onSubmit={() => updateEstimateItem(formData)}
                inputType="number"
                isPercent
              />
            ) : (
              <Text>{formData.taxesMultiplier}%</Text>
            )}
          </Box>
          <Box width="70%">
            <Text weight={600}>{USD(sessionEstimateItem?.subtotal_plus_fringe)}</Text>
          </Box>
          <Box width="10%" align="center">
            {canEdit && (
              <Button
                plain
                icon={<Trash size="16px" color="red" />}
                tip={`Delete ${sessionEstimateItem?.title}`}
                onClick={() => setShowDeleteModal(true)}
              />
            )}
          </Box>
        </Box>
        {canEdit && showDeleteModal && sessionEstimateItem && (
          <DeleteModal
            name={sessionEstimateItem.title}
            onDelete={deleteEstimateItem}
            onCancel={() => setShowDeleteModal(false)}
          />
        )}
      </Box>
    );
  }
);
