import { Box, Tip } from 'grommet';

export interface ITalonTipProps {
  maxWidth?: string;
  content: JSX.Element;
  children?: JSX.Element;
}

export const TalonTip = ({ maxWidth = 'medium', content, children }: ITalonTipProps) => {
  return (
    <Tip
      content={
        <Box width={{ max: maxWidth }} background="light-2" round="small" pad="xsmall" margin="xsmall" border="all">
          {content}
        </Box>
      }
      plain={true}
    >
      {children}
    </Tip>
  );
};
