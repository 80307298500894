/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateUsageEstimateItemRequest } from '../models/TCreateUsageEstimateItemRequest';
import type { TCreateUsageEstimateItemResponse } from '../models/TCreateUsageEstimateItemResponse';
import type { TGetUsageEstimateItemResponse } from '../models/TGetUsageEstimateItemResponse';
import type { TListUsageEstimateItemsResponse } from '../models/TListUsageEstimateItemsResponse';
import type { TUpdateUsageEstimateItemRequest } from '../models/TUpdateUsageEstimateItemRequest';
import type { TUpdateUsageEstimateItemResponse } from '../models/TUpdateUsageEstimateItemResponse';
import type { TUsageEstimateItemId } from '../models/TUsageEstimateItemId';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsageEstimateItemService {

    /**
     * Get UsageEstimateItem
     * @param id
     * @param relations
     * @returns TGetUsageEstimateItemResponse TGetUsageEstimateItemResponse
     * @throws ApiError
     */
    public static getUsageEstimateItem(
        id: TUsageEstimateItemId,
        relations?: string,
    ): CancelablePromise<TGetUsageEstimateItemResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/usage-estimate-items/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update UsageEstimateItem
     * @param id
     * @param requestBody
     * @returns TUpdateUsageEstimateItemResponse TUpdateUsageEstimateItemResponse
     * @throws ApiError
     */
    public static updateUsageEstimateItem(
        id: TUsageEstimateItemId,
        requestBody: TUpdateUsageEstimateItemRequest,
    ): CancelablePromise<TUpdateUsageEstimateItemResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/usage-estimate-items/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete UsageEstimateItem
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteUsageEstimateItem(
        id: TUsageEstimateItemId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/usage-estimate-items/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List UsageEstimateItems
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListUsageEstimateItemsResponse TListUsageEstimateItemsResponse
     * @throws ApiError
     */
    public static listUsageEstimateItems(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListUsageEstimateItemsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/usage-estimate-items',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create UsageEstimateItem
     * @param requestBody
     * @returns TCreateUsageEstimateItemResponse TCreateUsageEstimateItemResponse
     * @throws ApiError
     */
    public static createUsageEstimateItem(
        requestBody: TCreateUsageEstimateItemRequest,
    ): CancelablePromise<TCreateUsageEstimateItemResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/usage-estimate-items',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
