import dayjs from 'dayjs';
import { Multimedia } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { SessionRow } from '.';
import { TalonCard } from '../..';
import { CreateSessionModal } from './CreateSessionModal';
import { Location, Session, Shoot, TScriptId } from '/lib/api';
import { useEstimateStore, useProjectStore, useSessionStore } from '/src/context';

export interface ISessionsPanelProps {
  shoot: Shoot;
}

export const SessionsPanel = observer(({ shoot }: ISessionsPanelProps) => {
  /** Context **/
  const projectStore = useProjectStore();
  const estimateStore = useEstimateStore();
  const sessionStore = useSessionStore();

  /** State **/
  const [showAddModal, setShowAddModal] = useState(false);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);

  /** Methods **/
  const selectedNames = () => {
    const dates = selected.map((s) => sessions.find((item) => item.id === s)?.date);
    const formattedDates = dates.map((d) => new Date(d || '').toLocaleDateString());
    return formattedDates.join(', ');
  };

  const createSession = async (scriptId?: TScriptId) => {
    if (shoot) {
      const sortedSessions = sessions.toSorted((a, b) => (a.date > b.date ? 1 : -1));
      // TODO: can we get rid of the 'T00:00:00' here without causing any issues?
      const nextDate = sortedSessions.length
        ? dayjs(`${sortedSessions[sortedSessions.length - 1].date}T00:00:00`).add(1, 'day')
        : new Date();

      const session = await sessionStore.createSession(nextDate.toISOString(), shoot.id, scriptId);
      session && setSessions([...sessions, session]);
    }
  };

  const deleteSessions = async () => {
    // delete the selected sessions
    await Promise.all(selected.map((s) => sessionStore.deleteSession(shoot.id, s)));
    // remove the deleted sessions from the list
    setSessions(sessions.filter((session) => !selected.includes(session.id)));
    // refresh estimate totals
    await estimateStore.refreshEstimateTotals(estimateStore.currentEstimate?.id);
    setSelected([]);
  };

  /** Effects **/
  useEffect(() => {
    const getSessions = async () => {
      if (!shoot) {
        setSessions([]);
        return;
      }
      const s = await sessionStore.listSessions(shoot.id);
      s?.sort((a, b) => (a.date > b.date ? 1 : -1)).forEach((session) => {
        // sort activities within each session by start_date
        session.activities?.sort((a, b) => (a.start_time > b.start_time ? 1 : -1));
      });
      setSessions(s || []);
    };
    getSessions();
  }, [shoot]);

  useEffect(() => {
    const getLocations = async () => {
      if (!projectStore.currentProject) return;
      const l = await projectStore.getLocationsByProjectId(projectStore.currentProject.id);
      setLocations(l || []);
    };
    getLocations();
  }, []);

  const chooseSession = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  return (
    <TalonCard
      label="Session"
      labelPlural={`${shoot.name} Sessions`}
      newItem={() => createSession()}
      deleteItems={() => deleteSessions()}
      selectedNames={selectedNames()}
      addIcon={<Multimedia />}
    >
      {sessions.map((session) => (
        <SessionRow
          sessionId={session.id}
          locations={locations}
          onChecked={() => chooseSession(session.id)}
          checked={selected.includes(session.id)}
          key={session.id}
        />
      ))}
      {showAddModal && <CreateSessionModal addSession={createSession} setShowModal={setShowAddModal} shootId={shoot.id} />}
    </TalonCard>
  );
});
