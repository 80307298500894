import { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { FormField, TextInput } from 'grommet';

interface ICurrencyInputProps {
  placeholder?: string;
    value: number;
    onChange: (value: number) => void;
}

export const CurrencyInput = ({value, onChange, placeholder}:ICurrencyInputProps) => {
  const [currency, setCurrency] = useState('');
  const [selection, setSelection] = useState<
    [number | null, number | null] | null
  >(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value) {
      setCurrency(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value));
    }
  }, []);

  useLayoutEffect(() => {
    if (selection && ref?.current) {
      [ref.current.selectionStart, ref.current.selectionEnd] = selection;
    }
  }, [selection, currency]);

  return (
    <FormField label="Budget" htmlFor="budget">
      <TextInput
        ref={ref}
        value={currency}
        onFocus={(e) => setCurrency(e.target.value.replace(/[,$]/g, ''))}
        onBlur={(e) => e.target.value && setCurrency(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((parseFloat(e.target.value.replace(/[^0-9.]/g, '')))))}
        placeholder={placeholder}
        onChange={e => {
          setCurrency(e.target.value.replace(/[^0-9.]/g, ''));
          if (e.target.selectionStart !== null && e.target.selectionEnd !== null) {
            setSelection([e.target.selectionStart, e.target.selectionEnd]);
          }
          onChange(e.target.value ? parseFloat(e.target.value.replace(/[^0-9.]/g, '')) : 0);
        }}
      />
    </FormField>
  );
};
