/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UsageCycleType {
    _4_WEEK = '4 Week',
    _8_WEEK = '8 Week',
    _30_DAY = '30_Day',
    _31_DAY = '31_Day',
    _13_WEEK = '13 Week',
    _26_WEEK = '26 Week',
    _52_WEEK = '52 Week',
}
