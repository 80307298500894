import { Box, CheckBox, Select, SelectMultiple, Text, TextInput } from 'grommet';
import { User } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { Delete, TalonTip, UsageTypeSelect } from '../..';
import { CommercialType, Role, Usage, UsageCycleType, UsageType, UsageUnitType } from '/lib/api';

interface IUsageRowProps {
  commercialType: CommercialType;
  usage: Usage;
  roles: Role[];
  deleteUsage: (usageId: string) => Promise<void>;
  updateUsage: (update: Partial<Usage>) => Promise<void>;
}

export const UsageRow = observer(({ commercialType, usage, roles, deleteUsage, updateUsage }: IUsageRowProps) => {
  /** Render **/
  return (
    <Box
      direction="row"
      align="center"
      gap="medium"
      margin={{ left: 'xsmall' }}
      border={{ side: 'top', color: 'light-3' }}
    >
      <Box width="30%">
        <UsageTypeSelect
          val={usage.type}
          onChange={(value) => updateUsage({ type: value as UsageType })}
          commercialType={commercialType}
        />
      </Box>

      <Box flex="grow">
        <SelectMultiple
          options={roles ?? []}
          value={usage.roles?.reduce((acc, curr) => (curr.role_group ? acc : acc.concat(curr)), [] as Role[])}
          valueKey="id"
          labelKey="name"
          placeholder="Choose roles..."
          size="medium"
          onChange={({ value }) => updateUsage({ roles: value })}
          showSelectedInline
          icon={
            <TalonTip content={<Text>Choose Roles</Text>}>
              <User />
            </TalonTip>
          }
          valueLabel={(values) => (
            <Box wrap direction="column" width="small">
              {values && !!values.length ? (
                <>{values.flatMap((v: Role) => v.name).join(', ')}</>
              ) : (
                <Text color="text-weak">Select Roles</Text>
              )}
            </Box>
          )}
        />
      </Box>

      <Box direction="row" gap="xsmall" width="20%">
        <Box width="45%">
          <TextInput
            value={usage.units}
            type="number"
            placeholder="Units"
            onChange={(e) =>
              !Number.isNaN(parseInt(e.target.value ?? ''))
                ? updateUsage({ units: parseInt(e.target.value) })
                : undefined
            }
          />
        </Box>
        <Box>
          <Select
            value={usage.unit_type}
            onChange={({ value }) => updateUsage({ unit_type: value as UsageUnitType })}
            placeholder="Choose unit type..."
            options={Object.values(UsageUnitType)}
          />
        </Box>
      </Box>

      <Box direction="row" gap="xsmall" width="20%">
        <Box width="45%">
          <TextInput
            value={usage.cycles}
            type="number"
            placeholder="Cycles"
            onChange={(e) =>
              !Number.isNaN(parseInt(e.target.value ?? ''))
                ? updateUsage({ cycles: parseInt(e.target.value) })
                : undefined
            }
          />
        </Box>
        <Box>
          <Select
            value={usage.cycle_type}
            onChange={({ value }) => updateUsage({ cycle_type: value as UsageCycleType })}
            placeholder="Choose cycle type..."
            options={Object.values(UsageCycleType)}
          />
        </Box>
      </Box>

      <Box>
        <CheckBox
          name="is_demo"
          label="Demo"
          checked={usage.is_demo}
          onChange={(e) => updateUsage({ is_demo: e.target.checked })}
        />
      </Box>

      <Box>
        <Delete onClick={() => deleteUsage(usage.id)} name={`${usage.type} Usage`} />
      </Box>
    </Box>
  );
});
