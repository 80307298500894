import React from 'react';
import { Box, Button, Heading, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { Conversation, ConversationStatus } from '/lib/api';
import { TalonTip, Selector } from '..';
import { parseIsoDateString } from '/src/utils/time';

export interface IConversationListProps {
  selectConversation: (id: string) => void;
  updateConversation: ({id, name, status}: {id: string, name?: string, status?: ConversationStatus}) => void;
  setStatus: (status: ConversationStatus) => void;
  status: ConversationStatus;
  conversations: Conversation[];
  gridArea?: string;
}

export const ConversationList = observer(
  ({ selectConversation, conversations, updateConversation, setStatus, status }: IConversationListProps) => {
    const statusOptions = Object.values(ConversationStatus).map((status) => ({
      name: status,
      id: status
    }));

    return (
      <Box
        direction="column"
      >
        <Heading level="4" margin="medium">
            Conversations
          <Selector 
            options={statusOptions}
            value={status}
            onChange={(val) => setStatus(val as ConversationStatus)}
            size="small"
          />
        </Heading>

        <Box direction="column" gap="small" overflow={{vertical: 'scroll'}} pad={{horizontal: 'xsmall'}}>
          {conversations.map((c) => (
            <Box direction="row" key={c.id} border={{side: 'bottom', size: '1px', }} align='start' justify='between' flex='grow'>
              <TalonTip content={<Text>{c.name}</Text>}>
                <Box direction='column' pad="small">
                  <Button
                    plain
                    hoverIndicator
                    onClick={() => selectConversation(c.id)}
                    label={<Text weight={c.unread ? 'bold' : 'normal'} size="small">{c.name.length > 35 ? `${c.name.slice(0, 34)}...` : c.name}</Text>}
                  />
                  <Text size='xsmall'>{ parseIsoDateString(c.updated_at).date === parseIsoDateString(new Date().toISOString()).date ? parseIsoDateString(c.updated_at).time : parseIsoDateString(c.updated_at).date}</Text>
                </Box>
              </TalonTip>
              <TalonTip content={<Text size='xsmall'>Close conversation</Text>}>
                <Close size='small' onClick={() => updateConversation({id: c.id, status: ConversationStatus.CLOSED})} />
              </TalonTip>
            </Box>
          ))}
          {!conversations.length && (
            <Text>
            No conversations found.
            </Text>
          )}
        </Box>
      </Box>
    );
  }
);
