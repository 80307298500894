/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateUsageEstimateRequest } from '../models/TCreateUsageEstimateRequest';
import type { TCreateUsageEstimateResponse } from '../models/TCreateUsageEstimateResponse';
import type { TGetUsageEstimateResponse } from '../models/TGetUsageEstimateResponse';
import type { TListUsageEstimatesResponse } from '../models/TListUsageEstimatesResponse';
import type { TUpdateUsageEstimateRequest } from '../models/TUpdateUsageEstimateRequest';
import type { TUpdateUsageEstimateResponse } from '../models/TUpdateUsageEstimateResponse';
import type { TUsageEstimateId } from '../models/TUsageEstimateId';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsageEstimateService {

    /**
     * Get UsageEstimate
     * @param id
     * @param relations
     * @returns TGetUsageEstimateResponse TGetUsageEstimateResponse
     * @throws ApiError
     */
    public static getUsageEstimate(
        id: TUsageEstimateId,
        relations?: string,
    ): CancelablePromise<TGetUsageEstimateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/usage-estimates/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update UsageEstimate
     * @param id
     * @param requestBody
     * @returns TUpdateUsageEstimateResponse TUpdateUsageEstimateResponse
     * @throws ApiError
     */
    public static updateUsageEstimate(
        id: TUsageEstimateId,
        requestBody: TUpdateUsageEstimateRequest,
    ): CancelablePromise<TUpdateUsageEstimateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/usage-estimates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete UsageEstimate
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteUsageEstimate(
        id: TUsageEstimateId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/usage-estimates/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List UsageEstimates
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListUsageEstimatesResponse TListUsageEstimatesResponse
     * @throws ApiError
     */
    public static listUsageEstimates(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListUsageEstimatesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/usage-estimates',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create UsageEstimate
     * @param requestBody
     * @returns TCreateUsageEstimateResponse TCreateUsageEstimateResponse
     * @throws ApiError
     */
    public static createUsageEstimate(
        requestBody: TCreateUsageEstimateRequest,
    ): CancelablePromise<TCreateUsageEstimateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/usage-estimates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
