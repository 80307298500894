import { Box, Button, Card, CheckBox, Heading, Text } from 'grommet';
import { CircleInformation, ShareOption } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { EditableText } from '../EditableText';
import { TalonTip } from '../TalonTip';
import { Estimate, EstimateStatus } from '/lib/api';
import { useEstimateStore } from '/src/context';
import { USD } from '/src/utils/currency';

export interface IEstimateHeaderProps {
  estimate: Estimate;
  updateEstimate: (estimate: Estimate) => void;
}

export const EstimateHeader = observer(() => {
  /** Context **/
  const estimateStore = useEstimateStore();

  /** Methods **/
  const updateEstimate = async (updatedEstimate: Estimate) => {
    await estimateStore.updateEstimate(updatedEstimate);
    const estimate = await estimateStore.getEstimate(updatedEstimate.id, 'session_estimates,usage_estimates,shoot');
    if (estimate) estimateStore.setCurrentEstimate(estimate);
  };

  /** Render **/
  const StatusToggle = ({ width }: { width: string }) => (
    <Box width={width} flex="grow" direction="row" gap="small" pad="small" align="center" justify="end">
      <Button icon={<ShareOption color="plain" />} hoverIndicator tip='Share this estimate' />
      <Heading level="6" weight="normal" fill={false}>
        STATUS&nbsp;
        <TalonTip
          content={
            <Text>
              Draft estimates are &quot;ballpark&quot; estimates. Final estimates have been approved by the client.
            </Text>
          }
        >
          <CircleInformation size="small" />
        </TalonTip>
      </Heading>
      <CheckBox
        // pad="medium"
        label={
          <Box justify="center" width="xxsmall">
            <Text alignSelf="center" size="small">
              {estimateStore.currentEstimate?.status?.toUpperCase()}
            </Text>
          </Box>
        }
        checked={estimateStore.currentEstimate?.status === EstimateStatus.FINAL}
        onChange={(event) =>
          estimateStore.currentEstimate?.id &&
          updateEstimate({
            ...estimateStore.currentEstimate,
            status: event.target.checked ? EstimateStatus.FINAL : EstimateStatus.DRAFT,
          })
        }
        toggle
      />
    </Box>
  );

  return (
    <Card pad="medium" background="light-2" margin="medium" round="medium" overflow="auto">
      <Box direction="column">
        {/* Estimate Title */}
        <Box direction="row" justify="center">
          <Heading level="3" alignSelf="center">
            <EditableText
              val={estimateStore.currentEstimate?.title}
              onChange={(t) =>
                estimateStore.currentEstimate?.id && updateEstimate({ ...estimateStore.currentEstimate, title: t })
              }
              textSize="xlarge"
            />
          </Heading>
        </Box>
        <Box direction="row" justify="between" height="xxsmall">
          <Box width="30%" flex="grow" direction="row" gap="small" pad="small" align="center">
            {!!estimateStore.currentEstimate?.shoot && (
              <Box direction="row" gap="xsmall" align="end" fill="horizontal">
                <Text size="small">SHOOT:</Text>
                <Text>{estimateStore.currentEstimate?.shoot?.name}</Text>
              </Box>
            )}
          </Box>
          <Box width="40%" flex="grow" direction="row" gap="large" align="center">
            <Box direction="row" gap="xsmall" align="end" fill="horizontal">
              <Text size="small">TOTAL COST:</Text>
              <Text weight="bold">{USD(estimateStore.currentEstimate?.total)}</Text>
            </Box>
            <Box direction="row" gap="xsmall" align="end" fill="horizontal">
              <Text size="small">TOTAL + FRINGE:</Text>
              <Text weight="bold">{USD(estimateStore.currentEstimate?.total_plus_fringe)}</Text>
            </Box>
          </Box>
          <StatusToggle width="30%" />
        </Box>
      </Box>
    </Card>
  );
});
