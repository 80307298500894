import { Box, Spinner, Text } from 'grommet';
import { observer } from 'mobx-react-lite';
import { EstimateHeader, SessionEstimateList } from '..';
import { UsageEstimateList } from './UsageEstimateList';
import { useEstimateStore } from '/src/context';

export interface ICurrentEstimateProps {
  gridArea?: string;
}
export const CurrentEstimate = observer(({ gridArea }: ICurrentEstimateProps) => {
  const estimateStore = useEstimateStore();

  const hasEstimates = !!estimateStore.estimateList.length;

  return (
    <Box
      round="medium"
      background="white"
      border={{ color: '#d9d9e3' }}
      justify="start"
      alignContent="start"
      gridArea={gridArea}
      direction="column"
      flex="grow"
    >
      {estimateStore.isLoading && <Spinner />}
      {!estimateStore.isLoading && !hasEstimates && (
        <Box fill align="center" justify="center">
          <Text>Create or choose an Estimate to begin.</Text>
        </Box>
      )}

      {!estimateStore.isLoading && hasEstimates && (
        <Box>
          <EstimateHeader />
          <SessionEstimateList />
          <UsageEstimateList />
        </Box>
      )}
    </Box>
  );
});
