import { Box, Button, Keyboard, Layer, Select, Text, TextInput } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { TCommercialId } from '/lib/api';
import { useEstimateStore } from '/src/context';

export interface ICreateUsageEstimateModalProps {
  addUsageEstimate: (title: string, commercialId?: TCommercialId) => Promise<void>;
  setShowModal: (isVisible: boolean) => void;
}

export const CreateUsageEstimateModal = observer(
  ({ addUsageEstimate, setShowModal }: ICreateUsageEstimateModalProps) => {
    /** Context **/
    const estimateStore = useEstimateStore();

    /** State **/
    const [title, setTitle] = useState(
      `Usage Estimate #${(estimateStore.currentEstimate?.usage_estimates?.length ?? 0) + 1}`
    );
    const [commercialId, setCommercialId] = useState<TCommercialId>();

    /** Methods **/
    const createUsageEstimate = () => {
      addUsageEstimate(title, commercialId);
      setShowModal(false);
    };

    /** Render **/
    return (
      <Layer
        onEsc={() => setShowModal(false)}
        onClickOutside={() => setShowModal(false)}
        position="center"
        modal={true}
        margin="large"
      >
        <Box
          direction="column"
          align="start"
          pad="small"
          justify="around"
          width="medium"
          height="medium"
          background="light-1"
          round="small"
        >
          <Box pad="small" gap="small" justify="center" fill="horizontal">
            <Box>
              <Text>Title:</Text>
            </Box>
            <Box fill="horizontal">
              <Keyboard onEnter={() => createUsageEstimate()}>
                <TextInput value={title} onChange={(e) => setTitle(e.target.value)} />
              </Keyboard>
            </Box>
          </Box>
          <Box pad="small" gap="small" justify="center" fill="horizontal">
            <Box>
              <Text>Commercial:</Text>
            </Box>
            <Box fill="horizontal">
              <Select
                value={commercialId}
                options={estimateStore.currentEstimate?.shoot?.commercials ?? []}
                labelKey="title"
                valueKey="id"
                onChange={({ value }) => setCommercialId(value?.id)}
                placeholder="Choose..."
                emptySearchMessage="No Commercials found"
              />
            </Box>
          </Box>
          <Button
            pad="small"
            margin="small"
            hoverIndicator
            onClick={() => createUsageEstimate()}
            label="Add Usage Estimate"
          />
        </Box>
      </Layer>
    );
  }
);
