import { Box, Text } from 'grommet';
import { IMediaAssetLengthMap, IPerformerCountMap } from '.';
import { IAddress } from '/src/utils/decodeGoogle';
import { CommercialType, PerformerType } from '/lib/api';

interface ISummaryTabProps {
    name: string;
    client: string;
    product: string;
    budget: number;
    union: string | undefined;
    code: string;
    geography: string | undefined;
    place: IAddress | undefined;
    exclusivity: string | undefined;
    media: string[] | undefined;
    assetCounts: IMediaAssetLengthMap;
    performerCounts: IPerformerCountMap;
}

export const SummaryTab = (props: ISummaryTabProps) => {

  const {name, client, code, product, budget, union, geography, place, exclusivity, media, assetCounts, performerCounts} = props;

  return (
    
    <Box pad="medium" gap="medium">
      <Text>Below is a summary of everything you've filled in. Please confirm this is how you want to set the project up.</Text>
      {/* print all of the state variables */}
      <Box gap='small'>
        {name && <Text>Name: {name}</Text>}
        {code && <Text>Job Code: {code}</Text>}
        {client && <Text>Client: {client}</Text>}
        {product && <Text>Product: {product}</Text>}
        {!!budget && <Text>Budget: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(budget)}</Text>}
        {union && <Text>This {union === 'union' ? 'is a' : 'is not a'} union job.</Text>}
        {geography && <Text>It will be shot {geography === 'usa' ? 'onshore' : 'offshore'}{place && ` at ${place.street_address} ${place.city}, ${place.state} ${place.zip}`}.</Text>}
        {exclusivity && <Text>The client {exclusivity === 'yes' ? 'does' : 'does not'} require exclusivity.</Text>}
        {!!media?.length && <Text>The following media and assets will be produced:</Text>}
        {media?.map((medium) => (
          <Box direction='row' gap='small' pad={{left: 'large'}}><Text>{medium}</Text>
            { assetCounts[medium as keyof typeof CommercialType]?.map((length) => <Text>{length} sec.</Text>) }
          </Box>
        ))}
        {!!Object.keys(performerCounts)?.length && <Text>The following perfomers will be hired:</Text>}
        <Box pad={{left: 'large'}}>
          {Object.keys(performerCounts).map((performerType) => (
            <Text>{performerCounts[performerType as keyof typeof PerformerType]} {PerformerType[performerType as keyof typeof PerformerType]}</Text>
          ))}
        </Box>
      </Box>
    </Box>
    
  );
};
