/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreatePerformerRequest } from '../models/TCreatePerformerRequest';
import type { TCreatePerformerResponse } from '../models/TCreatePerformerResponse';
import type { TGetPerformerResponse } from '../models/TGetPerformerResponse';
import type { TListPerformersResponse } from '../models/TListPerformersResponse';
import type { TPerformerId } from '../models/TPerformerId';
import type { TUpdatePerformerRequest } from '../models/TUpdatePerformerRequest';
import type { TUpdatePerformerResponse } from '../models/TUpdatePerformerResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PerformerService {

    /**
     * Get Performer
     * @param id
     * @param relations
     * @returns TGetPerformerResponse TGetPerformerResponse
     * @throws ApiError
     */
    public static getPerformer(
        id: TPerformerId,
        relations?: string,
    ): CancelablePromise<TGetPerformerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/performers/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Performer
     * @param id
     * @param requestBody
     * @returns TUpdatePerformerResponse TUpdatePerformerResponse
     * @throws ApiError
     */
    public static updatePerformer(
        id: TPerformerId,
        requestBody: TUpdatePerformerRequest,
    ): CancelablePromise<TUpdatePerformerResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/performers/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Performer
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deletePerformer(
        id: TPerformerId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/performers/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Performers
     * @param projectId
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListPerformersResponse TListPerformersResponse
     * @throws ApiError
     */
    public static listPerformers(
        projectId?: string,
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListPerformersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/performers',
            query: {
                'projectId': projectId,
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Performer
     * @param requestBody
     * @returns TCreatePerformerResponse TCreatePerformerResponse
     * @throws ApiError
     */
    public static createPerformer(
        requestBody: TCreatePerformerRequest,
    ): CancelablePromise<TCreatePerformerResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/performers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
