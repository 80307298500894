import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually

import {
  CommercialService,
  Shoot,
  ShootService,
  TCommercialId,
  TCreateCommercialRequest,
  TCreateShootRequest,
  TProjectId,
  TShootId,
  TUpdateCommercialRequest,
} from '/lib/api';

export class ShootStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  listShoots = async (projectId: TProjectId) => {
    if (!projectId) return;
    try {
      const { shoots } = await ShootService.listShoots(
        projectId,
        undefined,
        undefined,
        undefined,
        'estimate,commercials,session'
      );
      return shoots;
    } catch (e) {
      this.handleError(e);
    }
  };


  /**
   * Shoots
   */

  createShoot = async (projectId: TProjectId, shootName?: string) => {
    if (!projectId) return;
    const createShootRequest: TCreateShootRequest = {
      name: shootName || 'New Shoot',
      projectId: projectId,
    };
    try {
      const { shoot } = await ShootService.createShoot(createShootRequest);
      return shoot;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateShoot = async (updatedShoot: Shoot) => {
    try {
      const { shoot } = await ShootService.updateShoot(updatedShoot.id, updatedShoot);
      if (!shoot || !this.rootStore.estimateStore.currentEstimate?.id || !shoot.project_id) return;
      if (this.rootStore.estimateStore.currentEstimate.shoot?.id === shoot.id) {
        this.rootStore.estimateStore.setCurrentEstimate({ ...this.rootStore.estimateStore.currentEstimate, shoot });
      }
      return shoot;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteShoot = async (shootId: TShootId) => {
    try {
      await ShootService.deleteShoot(shootId);
    } catch (e) {
      this.handleError(e);
    }
  };

  /**
   * Commercials
   */

  listCommercials = async (shootId: TShootId) => {
    try {
      const { commercials } = await CommercialService.listCommercials(
        undefined,
        undefined,
        undefined,
        'usages,edits,tags',
        shootId
      );
      return commercials;
    } catch (e) {
      this.handleError(e);
    }
  };

  getCommercial = async (commercialId: TCommercialId) => {
    try {
      const { commercial } = await CommercialService.getCommercial(commercialId, 'usages,edits,tags,project');
      return commercial;
    } catch (e) {
      this.handleError(e);
    }
  };


  createCommercial = async (newCommercial: TCreateCommercialRequest) => {
    try {
      const { commercial } = await CommercialService.createCommercial(newCommercial);
      return await this.getCommercial(commercial.id);
    } catch (e) {
      this.handleError(e);
    }
  };

  updateCommercial = async (commercialId: TCommercialId, updatedCommercial: TUpdateCommercialRequest) => {
    try {
      const { commercial } = await CommercialService.updateCommercial(commercialId, updatedCommercial);
      return commercial ? await this.getCommercial(commercial.id) : undefined;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteCommercial = async (commercialId: TCommercialId) => {
    try {
      await CommercialService.deleteCommercial(commercialId);
    } catch (e) {
      this.handleError(e);
    }
  };
}
