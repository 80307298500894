import { Box, CheckBox, Select, SelectMultiple, Text } from 'grommet';
import { Bike, Group, MapLocation, User, UserPolice, Yoga } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { RoleActivityRow } from '.';
import { DateTimeDropButtonV2, Delete, TalonTip } from '../..';
import {
  Costume,
  Location,
  Prop,
  Role,
  RoleGroup,
  Script,
  Session,
  SessionActivity,
  SessionActivityType,
} from '/lib/api';
import { AuditionActivityTypes, DateTimeType, ISODateString, TravelActivityTypes, WorkActivityTypes } from '/src/types';
import { getDateString } from '/src/utils/time';

interface ISessionActivityRowProps {
  script?: Script;
  session: Session;
  activity: SessionActivity;
  costumes: Costume[];
  props: Prop[];
  roles: Role[];
  roleGroups: RoleGroup[];
  locations: Location[];
  deleteSessionActivity: (activityId: string) => Promise<void>;
  updateSessionActivity: (update: Partial<SessionActivity>) => Promise<void>;
}

export const SessionActivityRow = observer(
  ({
    script,
    session,
    activity,
    costumes,
    props,
    roles,
    roleGroups,
    locations,
    deleteSessionActivity,
    updateSessionActivity,
  }: ISessionActivityRowProps) => {
    /** Methods **/
    const getBounds = (startDate: ISODateString) => {
      const d = new Date(startDate);
      const firstDayOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
      const plusOneYear = new Date(d.getFullYear() + 1, d.getMonth(), 1);
      return [firstDayOfMonth.toISOString(), plusOneYear.toISOString()];
    };

    /** Render **/
    return (
      <Box
        direction="row"
        align="center"
        justify='center'
        gap="medium"
        margin={{ left: 'xsmall' }}
        border={{ side: 'top', color: 'light-3' }}
      >
        <Box flex="grow">
          <DateTimeDropButtonV2
            dateTime={activity.start_time as ISODateString}
            onChange={(start_time) => start_time && updateSessionActivity({ start_time })}
            initialDate={getDateString(session.date)}
            session={session}
            activity={activity}
            bounds={getBounds(session.date)}
            type={DateTimeType.StartTime}
            placeholder="Start time"
          />
        </Box>
        <Text alignSelf="center">to</Text>
        <Box flex="grow">
          <DateTimeDropButtonV2
            dateTime={activity.end_time as ISODateString}
            onChange={(end_time) => end_time && updateSessionActivity({ end_time })}
            initialDate={getDateString(session.date)}
            session={session}
            activity={activity}
            bounds={getBounds(session.date)}
            type={DateTimeType.EndTime}
            placeholder="End time"
          />
        </Box>
        <Box flex="grow">
          <Select
            options={Object.values(SessionActivityType)}
            value={activity.type}
            placeholder="Choose activity type..."
            size="xsmall"
            onChange={({ value }) => updateSessionActivity({ type: value })}
            icon={
              <TalonTip content={<Text>Choose Activity Type</Text>}>
                <Yoga />
              </TalonTip>
            }
          />
        </Box>
        <Box direction='row' fill='horizontal' justify='start' gap='small' align='start' pad={{vertical: 'xsmall'}}>
          <Box>
            <SelectMultiple
              options={roles}
              value={activity.roles?.reduce((acc, curr) => (curr.role_group ? acc : acc.concat(curr)), [] as Role[])}
              valueKey="id"
              labelKey="name"
              placeholder="Choose roles..."
              size="medium"
              onChange={({ value }) => updateSessionActivity({ roles: value })}
              showSelectedInline
              disabled={!script?.id}
              icon={
                <TalonTip content={<Text>Choose Roles</Text>}>
                  <User />
                </TalonTip>
              }
              valueLabel={(values) => (
                <Box wrap direction="column" width="small">
                  {values && values.length ? (
                    <>
                      {values.map((role: Role) => {
                        return <RoleActivityRow role={role} activity={activity} key={role.id} />;
                      })}
                    </>
                  ) : (
                    <Text color="text-weak">Select Roles</Text>
                  )}
                </Box>
              )}
            />
          </Box>
          <Box>
            <SelectMultiple
              options={roleGroups}
              value={activity.role_groups}
              valueKey="id"
              labelKey="name"
              placeholder="Choose role groups..."
              size="medium"
              onChange={({ value }) => updateSessionActivity({ role_groups: value })}
              showSelectedInline
              disabled={!script?.id}
              icon={
                <TalonTip content={<Text>Choose Role Groups</Text>}>
                  <Group />
                </TalonTip>
              }
            />
          </Box>
          {activity.type === SessionActivityType.WARDROBE_CHANGE && (
            <Box>
              <SelectMultiple
                options={costumes}
                value={activity.costumes}
                valueKey="id"
                labelKey="name"
                placeholder="Choose costumes..."
                size="medium"
                onChange={({ value }) => updateSessionActivity({ costumes: value })}
                showSelectedInline
                disabled={!script?.id}
                icon={
                  <TalonTip content={<Text>Choose Costumes</Text>}>
                    <UserPolice />
                  </TalonTip>
                }
              />
            </Box>
          )}
          {WorkActivityTypes.includes(activity.type) && (
            <Box>
              <SelectMultiple
                options={props}
                value={activity.props}
                valueKey="id"
                labelKey="name"
                placeholder="Choose props..."
                size="medium"
                onChange={({ value }) => updateSessionActivity({ props: value })}
                showSelectedInline
                disabled={!script?.id}
                icon={
                  <TalonTip content={<Text>Choose Props</Text>}>
                    <Bike />
                  </TalonTip>
                }
              />
            </Box>
          )}
          {!TravelActivityTypes.includes(activity.type) && (
            <Box gap="small">
              <Select
                options={locations}
                value={activity.location}
                valueKey="id"
                labelKey="name"
                placeholder="Choose location..."
                size="medium"
                icon={
                  <TalonTip content={<Text>Choose Location</Text>}>
                    <MapLocation />
                  </TalonTip>
                }
                onChange={({ value }) => updateSessionActivity({ location: value })}
              />
            </Box>
          )}
          {TravelActivityTypes.includes(activity.type) && (
            <Box direction="row" gap="small">
              <Box>
                <Select
                  options={locations.filter((l) => l.id !== activity.to_location?.id)}
                  value={activity.from_location}
                  valueKey="id"
                  labelKey="name"
                  placeholder="Choose travel from location..."
                  size="medium"
                  icon={
                    <TalonTip content={<Text>Choose From Location</Text>}>
                      <MapLocation />
                    </TalonTip>
                  }
                  onChange={({ value }) => updateSessionActivity({ from_location: value })}
                />
              </Box>
              <Text alignSelf="center">to</Text>
              <Box>
                <Select
                  options={locations.filter((l) => l.id !== activity.from_location?.id)}
                  value={activity.to_location}
                  valueKey="id"
                  labelKey="name"
                  placeholder="Choose travel to location..."
                  size="medium"
                  icon={
                    <TalonTip content={<Text>Choose To Location</Text>}>
                      <MapLocation />
                    </TalonTip>
                  }
                  onChange={({ value }) => updateSessionActivity({ to_location: value })}
                />
              </Box>
            </Box>
          )}

          {AuditionActivityTypes.includes(activity.type) && (
            <Box direction="column" gap="small">
              <CheckBox
                label="Scratch track"
                checked={activity.is_scratch_track}
                onChange={(e) => updateSessionActivity({ is_scratch_track: e.target.checked })}
              />
              <CheckBox
                label="Creative session"
                checked={activity.is_creative_session}
                onChange={(e) => updateSessionActivity({ is_creative_session: e.target.checked })}
              />
            </Box>
          )}
        </Box>
        <Delete
          onClick={() => deleteSessionActivity(activity.id)}
          name={`${activity.type} ${new Date(activity.start_time).toLocaleTimeString()} to ${new Date(
            activity.end_time
          ).toLocaleTimeString()}`}
        />
      </Box>
    );
  }
);
