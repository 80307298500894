import { Box, Text, TextInput } from 'grommet';
import { PerformerType } from '/lib/api';
import { IPerformerCountMap } from './ProjectWizard';
interface IPerformerTabProps {
    setPeformerCounts: (m: IPerformerCountMap) => void;
    performerCounts: IPerformerCountMap;
}

export const PerformersTab = (props: IPerformerTabProps) => {
  const { setPeformerCounts, performerCounts } = props;
  const performerKeys = Object.keys(PerformerType) as Array<keyof typeof PerformerType>;

  return (  
    <Box gap="medium" overflow={{vertical: 'auto'}} height={{max: 'large'}} fill>
      <Box flex="grow" pad="medium">
        <Text>Almost done! Let's finish up by discussing performers. How many of each type do you expect will be needed?</Text>
        {performerKeys.map((key) => (
          <Box key={key} pad="small" direction="row" gap="small" justify='between' align='center' border='bottom'>
            <Box>
              <Text size='small'>{PerformerType[key]}</Text>
            </Box>
            <Box>
              <TextInput placeholder='Number' value={performerCounts[key]} size="small" width="xsmall" onChange={(e) => setPeformerCounts({...performerCounts, [key]: e.target.value})} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
