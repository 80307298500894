import { Box, Select, Text, TextInput } from 'grommet';
import { IMediaAssetLengthMap } from './ProjectWizard';
import { CommercialType } from '/lib/api';
interface IAssetsTabProps {
    media: string[] | undefined;
    setAssetCounts: (m: IMediaAssetLengthMap) => void;
    assetCounts: IMediaAssetLengthMap;
}

export const AssetsTab = (props: IAssetsTabProps) => {
  const { media, setAssetCounts, assetCounts } = props;
  const assetCountOptions = Array.from({ length: 100 }, (_, index) => index);

  const updateCounts = (medium: string, count: number) => {
    if (count === 0) {  
      setAssetCounts({...assetCounts, [medium]: []});
      return;
    }
    if(!assetCounts[medium as keyof typeof CommercialType]) {
      const arr = Array.from({ length: count }, () => 0);
      setAssetCounts({...assetCounts, [medium]: arr});
      return;
    } else {
      const arr = assetCounts[medium as keyof typeof CommercialType];
      if (arr.length === count) return;
      if (arr.length < count) {
        for (let i = arr.length; i < count; i++) {
          arr.push(0);
        }
        setAssetCounts({...assetCounts, [medium]: arr});
      }
      if (arr.length > count) {
        arr.splice(count, arr.length - count);
      }
      setAssetCounts({...assetCounts, [medium]: arr});
    }
  };

  const setLength = (medium: string, index: number, length: number) => {
    console.log(medium, index, length);
    const arr = assetCounts[medium as keyof typeof CommercialType];
    if (!arr) return;
    arr[index] = length;
    setAssetCounts({...assetCounts, [medium]: arr});
  };

  return (
    
    <Box pad="medium" gap="medium" overflow={{vertical: 'auto'}} height={{max: 'large'}} fill>
      {!media || media?.length === 0 ? <Text>Please go back one tab and select media types.</Text> : 
        <Box flex="grow" gap='small'>
          <Text>Great! Now let's talk about the media assets. How many of each will we have?</Text>
          {media?.map((medium , i) => (
            <Box key={`${medium}-${i}`} direction="row" gap="small" pad="small" border='bottom' height='small'>
              <Box direction="row" gap="small" width='50%' align='start'>
                <Select
                  options={assetCountOptions}
                  placeholder="Select"
                  value={assetCounts[medium as keyof typeof CommercialType]?.length}
                  onChange={(e) => updateCounts(medium, e.target.value)}
                  width='xsmall'
                  dropHeight='small'
                />
                <Text>{medium} Assets</Text>
              </Box>
              <Box direction="column" width='50%' overflow={{vertical: 'auto'}} height={{max: 'small'}}  >
                <Box flex="grow" gap="small">
                  <Text>Length in seconds:</Text>
                  {assetCounts[medium as keyof typeof CommercialType]?.map((length, j) => (
                    <Box direction="row" key={`${medium}-${j}`} gap="small" align='center' >
                      <Text>{j+1}:</Text> 
                      <TextInput 
                        placeholder='Length' 
                        value={length} 
                        width="xsmall" 
                        type='number' 
                        onChange={(e) => setLength(medium, j, parseInt(e.target.value))}
                        min={0}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>}
    </Box>
    
  );
};
