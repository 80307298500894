import { Box, CheckBox, Text } from 'grommet';
import { DocumentVideo } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { AddNewButton, CreateUsageModal, EditableTextV2, SelectableText, TalonTip, UsageRow } from '../..';

import { Commercial, CommercialType, TCommercialId, TUpdateCommercialRequest, Usage, UsageType } from '/lib/api';
import { useShootStore, useUsageStore } from '/src/context';

export interface ICommercialRowProps {
  commercialId: TCommercialId;
  onChecked: () => void;
  checked: boolean;
}

export const CommercialRow = observer(({ commercialId, onChecked, checked }: ICommercialRowProps) => {
  /** Context **/
  const usageStore = useUsageStore();
  const shootStore = useShootStore();

  /** State **/
  const [commercial, setCommercial] = useState<Commercial>();
  const [showAddModal, setShowAddModal] = useState(false);

  /** Methods **/
  const getCommercial = async (id: TCommercialId) => {
    const c = await shootStore.getCommercial(id);
    if (c) setCommercial(c);
  };

  const updateCommercial = async (update: TUpdateCommercialRequest) => {
    if (!commercial) return;
    const updatedCommercial = await shootStore.updateCommercial(commercial.id, update);
    updatedCommercial && setCommercial(updatedCommercial);
  };

  const addUsage = async (commercialId: TCommercialId, usageType: UsageType) => {
    const usage = await usageStore.createUsage({
      commercialId,
      title: `${usageType} Usage`,
      type: usageType,
      cycles: 1,
      units: 1,
      role_ids: [],
      is_demo: false,
    });

    if (usage && commercial) {
      const usages = [...commercial.usages, usage];
      const updatedCommercial = { ...commercial, usages };
      setCommercial(updatedCommercial as Commercial);
    }

    setShowAddModal(false);
  };

  const updateUsage = async (update: Usage) => {
    if (!commercial) return;
    const usage = await usageStore.updateUsage(update.id, {
      ...update,
      unit_type: update.unit_type ?? undefined,
      cycle_type: update.cycle_type ?? undefined,
      role_ids: update.roles?.map((r) => r.id),
    });
    if (!usage) return;
    const usages = commercial.usages?.map((u) => (u.id === usage.id ? usage : u));
    const updatedCommercial = { ...commercial, usages };
    setCommercial(updatedCommercial);
  };

  const deleteUsage = async (usageId: string) => {
    if (!commercial) return;
    await usageStore.deleteUsage(usageId);
    const usages = commercial?.usages?.filter((a) => a.id !== usageId);
    const updatedCommercial = { ...commercial, usages };
    setCommercial(updatedCommercial);
  };

  /** Effects **/
  useEffect(() => {
    getCommercial(commercialId);
  }, [commercialId]);

  /** Render **/
  return (
    <Box direction="column">
      <Box direction="row" align="center" background="light-2">
        <Box direction="row" gap="small" pad="small" align="center">
          <CheckBox onChange={onChecked} checked={checked} />
        </Box>
        <Box direction="row" gap="small" pad="small" align="center">
          <EditableTextV2 val={commercial?.title ?? ''} onChange={(title) => updateCommercial({ title })} />
        </Box>
        <Box direction="row" gap="small" pad="small" align="center">
          <Text>Type:</Text>
          <SelectableText
            options={Object.values(CommercialType).map((t) => ({ label: t, value: t }))}
            val={commercial?.type}
            onChange={(val) => updateCommercial({ type: val as CommercialType })}
          />
        </Box>
        <Box direction="row" gap="small" pad="small" align="center">
          <Text>Duration:</Text>
          <Box direction="row" gap="xxsmall">
            <EditableTextV2
              val={commercial?.duration?.toString() ?? ''}
              onChange={(val) => updateCommercial({ durationInSeconds: val ? parseInt(val) : 0 })}
            />
            <Text>seconds</Text>
          </Box>
        </Box>
        <Box direction="row" gap="small" pad="small" align="end">
          <AddNewButton
            icon={
              <TalonTip content={<Text>Add Usage Data</Text>}>
                <DocumentVideo />
              </TalonTip>
            }
            onClick={() => setShowAddModal(true)}
          />
        </Box>
      </Box>
      <Box direction="column" gap="small">
        {commercial?.usages?.map((usage) => (
          <UsageRow
            key={usage.id}
            commercialType={commercial.type}
            usage={usage}
            roles={commercial.project?.roles ?? []}
            updateUsage={(update) => updateUsage({ ...usage, ...update })}
            deleteUsage={(usageId) => deleteUsage(usageId)}
          />
        ))}
      </Box>
      {commercial && showAddModal && (
        <CreateUsageModal commercialId={commercial.id} addUsage={addUsage} setShowModal={setShowAddModal} />
      )}
    </Box>
  );
});
