import { Box, Button, Text } from 'grommet';
import { Trash } from 'grommet-icons';
import { capitalize } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { PerformerType, UsageCycleType, UsageEstimateItem, UsageType, UsageUnitType } from '/lib/api';
import { DeleteModal, EditableTextV2, SelectableText } from '/src/components';
import { useEstimateStore } from '/src/context';
import { TUsageEstimateItemForm } from '/src/types';
import { USD } from '/src/utils/currency';

export interface IUsageEstimateDataRowProps {
  usageEstimateItem: UsageEstimateItem;
  index: number;
  useColors?: boolean;
}

export const UsageEstimateDataRow = observer(({ usageEstimateItem, index, useColors }: IUsageEstimateDataRowProps) => {
  /** Refs **/
  const defaultFormData = useRef<TUsageEstimateItemForm>({
    title: 'New Usage',
    roleId: '',
    units: '0',
    cycles: '0',
    rate: '0',
    taxesMultiplier: '0',
    handlingMultiplier: '0',
    pAndHMultiplier: '0',
  });

  /** Context **/
  const estimateStore = useEstimateStore();

  /** State **/
  const [isUpdating, setIsUpdating] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [title] = useState(usageEstimateItem.title ?? defaultFormData.current.title);
  const [roleId] = useState(usageEstimateItem.role_id ?? defaultFormData.current.roleId);
  const [performerType, setPerformerType] = useState(
    usageEstimateItem.performer_type ?? defaultFormData.current.performerType
  );
  const [type, setType] = useState(usageEstimateItem.type ?? defaultFormData.current.type);
  const [units, setUnits] = useState(usageEstimateItem.units?.toString() ?? defaultFormData.current.units);
  const [unitType, setUnitType] = useState<UsageUnitType | undefined>(
    usageEstimateItem.unit_type ?? (defaultFormData.current.unit_type as UsageUnitType)
  );
  const [cycles, setCycles] = useState(usageEstimateItem.cycles?.toString() ?? defaultFormData.current.cycles);
  const [cycleType, setCycleType] = useState(usageEstimateItem.cycle_type ?? defaultFormData.current.cycleType);
  const [rate, setRate] = useState(usageEstimateItem.rate?.toString() ?? defaultFormData.current.rate);
  const [subtotal, setSubtotal] = useState(usageEstimateItem.subtotal?.toString() ?? '');
  const [taxesMultiplier, setTaxesMultiplier] = useState(
    usageEstimateItem.taxes_multiplier
      ? (usageEstimateItem.taxes_multiplier * 100).toString()
      : defaultFormData.current.taxesMultiplier
  );
  const [handlingMultiplier, setHandlingMultiplier] = useState(
    usageEstimateItem.handling_multiplier
      ? (usageEstimateItem.handling_multiplier * 100).toString()
      : defaultFormData.current.handlingMultiplier
  );
  const [pAndHMultiplier, setPAndHMultiplier] = useState(
    usageEstimateItem.p_and_h_multiplier
      ? (usageEstimateItem.p_and_h_multiplier * 100).toString()
      : defaultFormData.current.pAndHMultiplier
  );
  const [subtotalPlusFringe, setSubtotalPlusFringe] = useState(
    usageEstimateItem.subtotal_plus_fringe?.toString() ?? ''
  );

  /** Computed **/
  const canEdit = estimateStore.canEditCurrent;

  /** Methods **/
  const updateEstimateItem = async () => {
    if (isUpdating) return;

    setIsUpdating(true);

    const updatedItem = await estimateStore.updateUsageEstimateItem(usageEstimateItem.id, {
      title,
      roleId,
      type,
      performerType,
      units: parseInt(units || '0'),
      unitType: unitType as UsageUnitType,
      cycles: parseFloat(cycles || '0'),
      cycleType: cycleType as UsageCycleType,
      rate: parseFloat(rate || '0'),
      taxesMultiplier: parseFloat(taxesMultiplier || '0') / 100,
      handlingMultiplier: parseFloat(handlingMultiplier || '0') / 100,
      pAndHMultiplier: parseFloat(pAndHMultiplier || '0') / 100,
    });

    if (updatedItem?.rate?.toString() !== rate) setRate(updatedItem?.rate?.toString() ?? '');
    if (updatedItem?.subtotal?.toString() !== subtotal) setSubtotal(updatedItem?.subtotal?.toString() ?? '');
    if (updatedItem?.subtotal_plus_fringe?.toString() !== subtotalPlusFringe)
      setSubtotalPlusFringe(updatedItem?.subtotal_plus_fringe?.toString() ?? '');

    const t = ((updatedItem?.taxes_multiplier || 0) * 100).toString();
    if (t !== taxesMultiplier) setTaxesMultiplier(t);

    const h = ((updatedItem?.handling_multiplier || 0) * 100).toString();
    if (h !== handlingMultiplier) setHandlingMultiplier(h);

    const p = ((updatedItem?.p_and_h_multiplier || 0) * 100).toString();
    if (p !== pAndHMultiplier) setPAndHMultiplier(p);

    setIsUpdating(false);
  };

  const deleteEstimateItem = async () => {
    setShowDeleteModal(false);
    await estimateStore.deleteUsageEstimateItem(usageEstimateItem.id);
  };

  /** Effects **/
  useEffect(() => {
    updateEstimateItem();
  }, [performerType, type, unitType, cycleType]);

  /** Render **/
  return (
    <Box>
      <Box
        direction="row"
        gap="medium"
        pad="medium"
        align="center"
        background={useColors && !(index % 2) ? 'light-1' : 'white'}
      >
        <Box width="75%">
          {canEdit ? (
            <SelectableText
              val={performerType}
              options={Object.values(PerformerType).map((t) => ({ label: t, value: t }))}
              onChange={(value) => setPerformerType(value as PerformerType)}
              onBlur={() => updateEstimateItem()}
              
            />
          ) : (
            <Text>{usageEstimateItem.role?.name || '-'}</Text>
          )}
        </Box>

        <Box fill="horizontal">
          {canEdit ? (
            <SelectableText
              val={type}
              options={Object.values(UsageType).map((t) => ({ label: t, value: t }))}
              onChange={(value) => setType(value as UsageType)}
              onBlur={() => updateEstimateItem()}
              
            />
          ) : (
            <Text>{type}</Text>
          )}
        </Box>

        <Box width="75%" direction="row" gap="small" align="center">
          <Box>
            {canEdit ? (
              <EditableTextV2
                val={units}
                onChange={(units) => setUnits(units)}
                onBlur={() => updateEstimateItem()}
                onSubmit={() => updateEstimateItem()}
                minValue={1}
                inputType="number"
              />
            ) : (
              <Text>{units}</Text>
            )}
          </Box>
          <Box>
            {canEdit ? (
              <SelectableText
                val={unitType}
                options={Object.values(UsageUnitType).map((t) => ({ label: t, value: t }))}
                onChange={(value) => setUnitType(value as UsageUnitType)}
                onBlur={() => updateEstimateItem()}
                
              />
            ) : (
              <Text>{capitalize(unitType)}</Text>
            )}
          </Box>
        </Box>

        <Box width="75%" direction="row" gap="small" align="center">
          <Box>
            {canEdit ? (
              <EditableTextV2
                val={cycles}
                onChange={(cycles) => setCycles(cycles)}
                onBlur={() => updateEstimateItem()}
                onSubmit={() => updateEstimateItem()}
                minValue={1}
                inputType="number"
              />
            ) : (
              <Text>{cycles}</Text>
            )}
          </Box>
          <Box>
            {canEdit ? (
              <SelectableText
                val={cycleType}
                options={Object.values(UsageCycleType).map((t) => ({ label: t, value: t }))}
                onChange={(value) => setCycleType(value as UsageCycleType)}
                onBlur={() => updateEstimateItem()}
                
              />
            ) : (
              <Text>{capitalize(cycleType)}</Text>
            )}
          </Box>
        </Box>

        <Box width="55%">
          {canEdit ? (
            <EditableTextV2
              val={rate}
              onChange={(rate) => setRate(rate)}
              onBlur={() => updateEstimateItem()}
              onSubmit={() => updateEstimateItem()}
              inputType="number"
              isCurrency
            />
          ) : (
            <Text>{USD(rate)}</Text>
          )}
        </Box>
        <Box width="60%">
          <Text weight={600}>{USD(usageEstimateItem?.subtotal)}</Text>
        </Box>
        <Box width="40%">
          {canEdit ? (
            <EditableTextV2
              val={pAndHMultiplier}
              onChange={(pAndHMultiplier) => setPAndHMultiplier(pAndHMultiplier)}
              onBlur={() => updateEstimateItem()}
              onSubmit={() => updateEstimateItem()}
              inputType="number"
              isPercent
            />
          ) : (
            <Text>{pAndHMultiplier}%</Text>
          )}
        </Box>
        <Box width="40%">
          {canEdit ? (
            <EditableTextV2
              val={handlingMultiplier}
              onChange={(handlingMultiplier) => setHandlingMultiplier(handlingMultiplier)}
              onBlur={() => updateEstimateItem()}
              onSubmit={() => updateEstimateItem()}
              inputType="number"
              isPercent
            />
          ) : (
            <Text>{handlingMultiplier}%</Text>
          )}
        </Box>
        <Box width="40%">
          {canEdit ? (
            <EditableTextV2
              val={taxesMultiplier}
              onChange={(taxesMultiplier) => setTaxesMultiplier(taxesMultiplier)}
              onBlur={() => updateEstimateItem()}
              onSubmit={() => updateEstimateItem()}
              inputType="number"
              isPercent
            />
          ) : (
            <Text>{taxesMultiplier}%</Text>
          )}
        </Box>
        <Box width="70%">
          <Text weight={600}>{USD(usageEstimateItem?.subtotal_plus_fringe)}</Text>
        </Box>
        <Box width="10%" align="center">
          {canEdit && (
            <Button
              plain
              icon={<Trash size="16px" color="red" />}
              tip={`Delete ${usageEstimateItem?.title}`}
              onClick={() => setShowDeleteModal(true)}
            />
          )}
        </Box>
      </Box>
      {canEdit && showDeleteModal && usageEstimateItem && (
        <DeleteModal
          name={usageEstimateItem.title}
          onDelete={deleteEstimateItem}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </Box>
  );
});
