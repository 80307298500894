import { Box } from 'grommet';
import { useEffect, useState } from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';
import { GoogleMap, GoogleMapAutoComplete } from '..';
import { useProjectStore } from '../../../context';
import { getAddressObject, IAddress } from '../../../utils/decodeGoogle';

interface ILocationProps {
    place?: IAddress
    id: string;
    updatePlace: (place: IAddress) => void;
}

export const Location = (props: ILocationProps) => {
  const { place, updatePlace } = props;
  const { street_address, city, state, zip, lat, lng, id } = place ?? {};
  const projectStore = useProjectStore();
  const [apiKey, setApiKey] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getApiKey = async () => {
      const apiKey = await projectStore.getMapApiKey();
      setApiKey(apiKey);
    };
    getApiKey();
  }, []);

  const extractAddress = async (place: google.maps.places.PlaceResult) => {
    if (!place.address_components) return;
    const address = getAddressObject(place.address_components);
    address.lat = place.geometry?.location?.lat() || -1;
    address.lng = place.geometry?.location?.lng() || -1;
  
    updatePlace(address);
  };

  return (
    <Box direction="column" pad="small">
      {apiKey && (
        <APIProvider apiKey={apiKey} libraries={['places']}>
          <Box pad="small" gap='small'>
            <GoogleMapAutoComplete
              onPlaceSelected={(place) => extractAddress(place)}
              defaultValue={
                street_address !== undefined
                  ? `${street_address} ${city} ${state} ${zip}`
                  : undefined
              }
              options={{
                componentRestrictions: { country: 'us' },
                types: ['address'],
                fields: ['address_components', 'geometry', 'name'],
              }}
              placeholder="Search for a location..."
            />
            {!!(lat && lng) && (
              <GoogleMap
                apiKey={apiKey || ''}
                position={{ lat: lat, lng: lng }}
                zoom={10}
                id={typeof id === 'string' ? id : ''}
              />
            )}
          </Box>
        </APIProvider>
      )}
    </Box>
  );};

