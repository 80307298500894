/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MessageType {
    AI_MESSAGE = 'aiMessage',
    USER_MESSAGE = 'userMessage',
    SYSTEM_MESSAGE = 'systemMessage',
    SUPPORT_MESSAGE = 'supportMessage',
    ERROR_MESSAGE = 'errorMessage',
    NEW_CONVERSATION = 'newConversation',
    UPDATED_CONVERSATION = 'updatedConversation',
}
