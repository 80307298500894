import { useEffect, useRef, useState, useContext } from 'react';
import TalonLogo from 'jsx:/public/images/talon-ai-logo.svg';
import { Box, Card, CardFooter, CardHeader, Collapsible, Text } from 'grommet';
import { ThemeContext } from 'styled-components';
import { Chat, ChatOption, Close} from 'grommet-icons';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useLocation } from 'react-router-dom';
import { ChatService, ChatMessage, MessageType } from '/lib/api';
import { observer } from 'mobx-react-lite';

import { toast } from 'react-toastify';
import { AddNewButton, MessageList, UserInput } from '../../../components/ui';
import { useUserStore } from '../../../context';
import { SupportChat } from '.';

const defaultState = {
  messages: [
    {
      text: 'Hi, what would you like to know about working with talent?',
      type: MessageType.AI_MESSAGE,
    },
  ],
};

export const ChatWidget = observer(() => {
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [messageState, setMessageState] = useState<{
    conversationId?: string;
    messages: Array<Partial<ChatMessage>>;
    pending?: string;
  }>(defaultState);
  const [messageToSend, setMessageToSend] = useState<Partial<ChatMessage>>();
  const [showChat, setShowChat] = useState<boolean>(false);
  const [supportChat, setSupportChat] = useState<boolean>(false);
  const [dragStartPos, setDragStartPos] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startingY, setStartingY] = useState(0);
  const userStore = useUserStore();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const theme = useContext(ThemeContext);
  useEffect(() => {
    if(dragRef.current) {
      setStartingY(dragRef.current.getBoundingClientRect().top);
    }
  }, [dragRef.current]);

  //handle form submission
  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const { conversationId } = messageState;
    const message = {
      type: MessageType.USER_MESSAGE,
      text: query.trim(),
      conversation_id: conversationId,
    };

    setLoading(true);
    setQuery('');

    if(supportChat) {
      setMessageToSend(message);
      setLoading(false);
      return;
    } else {
      try {
        setMessageState((state) => ({
          ...state,
          messages: [
            ...state.messages,
            { ...message },
          ],
        }));
        const data = await ChatService.chat({
          question: message.text,
          conversationId,
        });

        setMessageState((state) => ({
          ...state,
          messages: [
            ...state.messages,
            { ...data },
          ],
          conversationId: data.conversation_id,
        }));

        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error('An error occurred while fetching the data. Please try again.');
        console.log('error', error);
      }
    }
  }

  //prevent empty submissions
  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && query) {
      handleSubmit(e);
    } else if (e.key == 'Enter') {
      e.preventDefault();
    }
  };

  if (!userStore.user?.id) {
    return null;
  }

  const toggleSupportChat = () => {
    setSupportChat(!supportChat);
  };

  const onStart = (e: DraggableEvent, data: DraggableData) => {
    setDragStartPos({ x: data.x, y: data.y });
  };
  
  const onStop = (e: DraggableEvent, data: DraggableData) => {
    const deltaX = Math.abs(data.x - dragStartPos.x);
    const deltaY = Math.abs(data.y - dragStartPos.y);
    if (deltaX < 5 || deltaY < 5) {
      const height = Number(theme?.global?.size?.large?.substring(0, theme?.global?.size?.large?.length - 2));
      // click
      console.log('Click', data, height);

      if (!showChat) {
        if (Math.abs(startingY + data.y) - height < 0) {
          setShowChat(true);
          setPosition({ x: data.x, y: height - startingY });
        } else {
          setShowChat(true);
        }
      }
    } else {
      console.log('Drag', data);
    }
  };

  return (
    !location.pathname.includes('/admin') ? (
      <Draggable 
        bounds="parent" 
        handle='.handle' 
        nodeRef={dragRef} 
        onStart={onStart} 
        onStop={onStop} 
        position={position} 
        onDrag={(e, data) => setPosition({ x: data.x, y: data.y })}
        axis='both'
      >
        <Box pad="small" round={true} style={{ position: 'fixed', bottom: '10%', right: '5%', zIndex: '10' }} ref={dragRef}>
          {!showChat && (
            <Box
              background="brand"
              round={true}
              height="xxsmall"
              width="small"
              align="center"
              justify="center"
              elevation="medium"
              direction="row"
              gap="small"
              border={{ color: 'accent-1', size: 'small' }}
              style={{ cursor: 'move' }}
              className='handle'
            >
              <Text style={{cursor: 'pointer'}}>
                {supportChat ? 'Support Chat' : 'AI Chat'}
              </Text>
              <Chat size="medium" enableBackground="true" style={{cursor: 'pointer'}}/>
            </Box>
          )}
          <Collapsible direction="vertical" open={showChat}>
            <Card
              height="large"
              width="large"
              background="light-1"
              border="all"
              elevation="medium"
              round={{corner: 'top' }} 
            >
              <Box direction="column" height="100%">
                <CardHeader pad="small" background={supportChat ? 'accent-3' : 'brand'} justify="between" className='handle' style={{cursor: 'move'}}>
                  <Text>
                    {supportChat ? 'Support Chat' : 'AI Chat'}
                  </Text>
                  <Close onClick={() => setShowChat(false)} cursor="pointer" />
                </CardHeader>
                <MessageList messages={messageState.messages}/>
                <CardFooter direction="column" align='center' gap='none'>
                  {supportChat && (
                    <SupportChat setMessageState={setMessageState} messageToSend={messageToSend} conversationId={messageState.conversationId || ''}/>
                  )}
                  <Box direction="row" width='100%'>
                    <UserInput
                      handleSubmit={handleSubmit}
                      loading={loading}
                      handleEnter={handleEnter}
                      textAreaRef={textAreaRef}
                      query={query}
                      setQuery={setQuery}
                    />
                    <AddNewButton icon={<Box align="center" height="30px" width="30px"><TalonLogo /></Box>} onClick={() => setMessageState(defaultState)} tipText='New AI Chat'/>
                    <AddNewButton icon={<ChatOption />} onClick={()=> toggleSupportChat()} tipText='Talk with a talent expert.' active={supportChat} />
                  </Box>
                  <Text margin={{horizontal: 'small'}} size="xsmall">Remember that AI can make mistakes. Please be sure to check sources it cites.</Text>
                </CardFooter>
              </Box> 
            </Card>
          </Collapsible>
        </Box>
      </Draggable>) :
      null
  );
});
