import { Box, Button, Text } from 'grommet';
import { Trash } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { DeleteModal } from '../../DeleteModal';
import { EditableTextV2 } from '../../EditableTextV2';
import { UsageEstimate } from '/lib/api';
import { useEstimateStore } from '/src/context';
import { USD } from '/src/utils/currency';

export interface IUsageEstimateHeaderProps {
  usageEstimate: UsageEstimate;
  setUsageEstimate: (usageEstimate: UsageEstimate) => void;
}

export const UsageEstimateHeader = observer(({ usageEstimate, setUsageEstimate }: IUsageEstimateHeaderProps) => {
  /** Context **/
  const estimateStore = useEstimateStore();

  /** State **/
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [title, setTitle] = useState(usageEstimate.title);

  /** Computed **/
  const canEdit = estimateStore.canEditCurrent;

  /** Methods **/
  const deleteUsageEstimate = async () => {
    await estimateStore.deleteUsageEstimate(usageEstimate.id);
  };

  /** Render **/
  return (
    <Box>
      <Box
        direction="row"
        gap="small"
        background="brand"
        style={{
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        }}
        pad="medium"
        align="center"
      >
        <Box fill="horizontal">
          {canEdit ? (
            <EditableTextV2
              val={title}
              onChange={setTitle}
              onSubmit={() => setUsageEstimate({ ...usageEstimate, title })}
              onBlur={() => setUsageEstimate({ ...usageEstimate, title })}
            />
          ) : (
            <Text>{title}</Text>
          )}
        </Box>
        <Box direction="row" gap="xsmall" align="end" fill="horizontal">
          <Text size="small">SUBTOTAL:</Text>
          <Text weight="bold">{USD(usageEstimate.subtotal)}</Text>
        </Box>
        <Box direction="row" gap="xsmall" align="end" fill="horizontal">
          <Text size="small">SUBTOTAL + FRINGE:</Text>
          <Text weight="bold">{USD(usageEstimate.subtotal_plus_fringe)}</Text>
        </Box>
        {canEdit && (
          <Box width="10%" align="center">
            <Button
              plain
              icon={<Trash size="16px" color="red" />}
              tip={`Delete ${usageEstimate?.title}`}
              onClick={() => setShowDeleteModal(true)}
            />
          </Box>
        )}
      </Box>
      {showDeleteModal && usageEstimate && (
        <DeleteModal
          name={usageEstimate.title}
          onDelete={deleteUsageEstimate}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </Box>
  );
});
