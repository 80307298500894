/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TCreateSessionEstimateItemRequest } from '../models/TCreateSessionEstimateItemRequest';
import type { TCreateSessionEstimateItemResponse } from '../models/TCreateSessionEstimateItemResponse';
import type { TGetSessionEstimateItemResponse } from '../models/TGetSessionEstimateItemResponse';
import type { TListSessionEstimateItemsResponse } from '../models/TListSessionEstimateItemsResponse';
import type { TSessionEstimateItemId } from '../models/TSessionEstimateItemId';
import type { TUpdateSessionEstimateItemRequest } from '../models/TUpdateSessionEstimateItemRequest';
import type { TUpdateSessionEstimateItemResponse } from '../models/TUpdateSessionEstimateItemResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SessionEstimateItemService {

    /**
     * Get Session Estimate Item
     * @param id
     * @param relations
     * @returns TGetSessionEstimateItemResponse TGetSessionEstimateItemResponse
     * @throws ApiError
     */
    public static getSessionEstimateItem(
        id: TSessionEstimateItemId,
        relations?: string,
    ): CancelablePromise<TGetSessionEstimateItemResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/session-estimate-items/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }

    /**
     * Update Session Estimate Item
     * @param id
     * @param requestBody
     * @returns TUpdateSessionEstimateItemResponse TUpdateSessionEstimateItemResponse
     * @throws ApiError
     */
    public static updateSessionEstimateItem(
        id: TSessionEstimateItemId,
        requestBody: TUpdateSessionEstimateItemRequest,
    ): CancelablePromise<TUpdateSessionEstimateItemResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/session-estimate-items/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Session Estimate Item
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteSessionEstimateItem(
        id: TSessionEstimateItemId,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/session-estimate-items/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List Session Estimate Items
     * @param limit
     * @param page
     * @param orderAsJsonString
     * @param relations
     * @returns TListSessionEstimateItemsResponse TListSessionEstimateItemsResponse
     * @throws ApiError
     */
    public static listSessionEstimateItems(
        limit?: number,
        page?: number,
        orderAsJsonString?: string,
        relations?: string,
    ): CancelablePromise<TListSessionEstimateItemsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/session-estimate-items',
            query: {
                'limit': limit,
                'page': page,
                'orderAsJsonString': orderAsJsonString,
                'relations': relations,
            },
        });
    }

    /**
     * Create Session Estimate Item
     * @param requestBody
     * @returns TCreateSessionEstimateItemResponse TCreateSessionEstimateItemResponse
     * @throws ApiError
     */
    public static createSessionEstimateItem(
        requestBody: TCreateSessionEstimateItemRequest,
    ): CancelablePromise<TCreateSessionEstimateItemResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/session-estimate-items',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
