import { Box, Button, Text } from 'grommet';
import { Add } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEstimateStore } from '/src/context';

export interface IUsageEstimateFooterProps {
  addEstimateItem: () => void | Promise<void>;
}

export const UsageEstimateFooter = observer(({ addEstimateItem }: IUsageEstimateFooterProps) => {
  /** Context **/
  const estimateStore = useEstimateStore();

  /** Computed **/
  const canEdit = estimateStore.canEditCurrent;

  /** Render **/
  return (
    <Box
      gap="small"
      pad={{ vertical: 'small', horizontal: 'medium' }}
      background="white"
      style={{
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
      }}
    >
      {canEdit && <Box width="15%" pad={{ vertical: 'xsmall' }}>
        <Button
          secondary
          label={
            <Text size="small" weight="bold">
              ADD ITEM
            </Text>
          }
          icon={<Add size="16px" />}
          tip="Add estimate item"
          onClick={() => addEstimateItem()}
        />
      </Box>}
    </Box>
  );
});
