import { Box, DateInput, Text, RadioButtonGroup } from 'grommet';
import { Location } from '../project-home';
import { IAddress } from '../../../utils/decodeGoogle';
import { useEffect } from 'react';
import { LocationType } from '/lib/api';

import { Hint } from '.';
interface IGeographyTabProps {
    geography?: string;
    union?: string;
    firstSessionDate?: string;
    place?: IAddress;
    locationType?: LocationType;
    setGeography: (value: string) => void;
    setUnion: (value: string) => void;
    setFirstSessionDate: (value: string) => void;
    setPlace: (place: IAddress | undefined) => void;
    setLocationType: (value?: LocationType) => void;
}

export const GeographyTab = (props: IGeographyTabProps) => {
  const {geography, union, firstSessionDate, place, locationType, setGeography, setUnion, setFirstSessionDate, setPlace, setLocationType} = props;

  useEffect(() => {
    if (geography === 'offshore') {
      setUnion('nonunion');
      setPlace(undefined);
      setLocationType(undefined);
    }
  }, [geography, setUnion]);
  return (
    
    <Box pad="medium" gap="medium" overflow={{vertical: 'auto'}} direction="row" height={{max: 'large'}}>
      <Box direction="column" width="50%" gap="medium">
        <Text>Alright! Now, where will the shoot(s) take place?</Text>
        <Box>
          <RadioButtonGroup direction="column" name="geography" options={[{label: 'USA', value: 'usa'}, {label: 'Offshore', value: 'offshore'}]} value={geography} onChange={(e) => setGeography(e.target.value)}/>
          {geography === 'usa' &&  (
            <Box gap="small">              
              <Location 
                id='new'
                place={place}
                updatePlace={setPlace}
              />
              <Text>Talent will:</Text>
              <RadioButtonGroup direction="column" name="union" options={[{label: 'Return to the reporting site EOD', value: LocationType.NEARBY}, {label: 'Stay overnight', value: LocationType.DISTANT}]} value={locationType} onChange={(e) => setLocationType(e.target.value as unknown as LocationType)}/>
              <Text>Will this be a union or non-union shoot?</Text>
              <RadioButtonGroup direction="column" name="union" options={[{label: 'Union', value: 'union'}, {label: 'Non-Union', value: 'nonunion'}]} value={union} onChange={(e) => setUnion(e.target.value)}/>
            </Box>)}
        </Box>
      </Box>
      <Box direction="column" width="50%">
        <Text>... and when will the first session be?</Text>
        <Box gap="small" direction='row' align='center'>
          <Text>{firstSessionDate && new Date(firstSessionDate).toLocaleDateString()}</Text>
          <DateInput value={firstSessionDate} onChange={({value}) => setFirstSessionDate(Array.isArray(value) ? value[0] : value)}/>
        </Box>
        { geography === 'offshore' &&  (
          <Box pad="small">
            <Hint>
              <Text>Since this shoot is offshore, it is automatically non-union.</Text>
            </Hint>
          </Box>)}
      </Box>
    </Box>
    
  );
};