import styled, { css } from 'styled-components';
import { Filter, Favorite } from 'grommet-icons';

const filledIcon = css`
  path[fill="none"] {
    fill: rgb(229 36 59) !important;
  }
  polygon[fill="none"] {
    fill: rgb(229 36 59) !important;
  }
`;

interface FilledIconProps {
  filled?: boolean;
}

export const FavoriteFilled = styled(Favorite)<FilledIconProps>`
  ${(props) => (props.filled ? filledIcon : '')}
`;

export const FilterFilled = styled(Filter)<FilledIconProps>`
  ${(props) => (props.filled ? filledIcon : '')}
  `;
