import { Box } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { SessionEstimateDataRow } from './SessionEstimateDataRow';
import { SessionEstimateFooter } from './SessionEstimateFooter';
import { SessionEstimateHeader } from './SessionEstimateHeader';
import { TitleRow } from './TitleRow';
import { SessionEstimate } from '/lib/api';
import { useEstimateStore } from '/src/context';

export interface ISessionEstimatePanelProps {
  sessionEstimate: SessionEstimate;
}

export const SessionEstimatePanel = observer(({ sessionEstimate }: ISessionEstimatePanelProps) => {
  /** Context **/
  const estimateStore = useEstimateStore();

  /** State **/
  const [isUpdatingSession, setIsUpdatingSession] = useState(false);

  /** Computed **/
  const estimateItems = useMemo(
    () => sessionEstimate.items?.toSorted((a, b) => (a.created_at > b.created_at ? 1 : -1)),
    [sessionEstimate.items]
  );

  /** Methods **/

  /**
   * Update this panel's SessionEstimate
   * @param sessionEstimate
   * @param sessionEstimateIndex
   * @returns
   */
  const updateSessionEstimate = async (sessionEstimate: SessionEstimate) => {
    if (isUpdatingSession) return;
    else if (!estimateStore.currentEstimate) {
      console.warn('No estimate loaded!');
      return;
    }

    setIsUpdatingSession(true);
    await estimateStore.updateSessionEstimate(sessionEstimate.id, sessionEstimate.title, sessionEstimate.date);
    setIsUpdatingSession(false);
  };

  const addEstimateItem = async () => {
    await estimateStore.createSessionEstimateItem(sessionEstimate.id);
  };

  /** Render **/
  return (
    <Box margin="small" key={sessionEstimate.id} fill="horizontal" round="15px" background="light-3" elevation="small">
      {/* Header */}
      <SessionEstimateHeader
        sessionEstimate={sessionEstimate}
        setSessionEstimate={(sessionEstimate) => updateSessionEstimate(sessionEstimate)}
      />

      {/* Data Table */}
      <Box border={{ side: 'bottom', size: 'xsmall' }}>
        <TitleRow />
        {estimateItems.map((item, i) => (
          <SessionEstimateDataRow key={item.id} sessionEstimateItem={item} index={i} useColors />
        ))}
      </Box>

      {/* Footer */}
      <SessionEstimateFooter addEstimateItem={addEstimateItem} />
    </Box>
  );
});
