import { Select, SelectProps } from 'grommet';
import { DocumentVideo } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { CommercialType, UsageType } from '/lib/api';

export interface IUsageTypeSelectProps {
  onChange: (val: UsageType) => void;
  commercialType?: CommercialType;
  val?: UsageType;
  id?: string;
  name?: string;
  dropAlign?: SelectProps['dropAlign'];
}

export const UsageTypeSelect = observer(
  ({ commercialType, val, onChange, id, name, dropAlign }: IUsageTypeSelectProps) => {
    /** State **/
    const [typeOptions, setTypeOptions] = useState<UsageType[]>(Object.values(UsageType));

    /** Methods **/
    const filterTypes = (query: string) => {
      if (query === '') {
        setTypeOptions(Object.values(UsageType));
        return;
      }
      const exp = new RegExp(query.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
      const filtered = Object.values(UsageType).filter((t) => exp.test(t));
      setTypeOptions(filtered);
    };

    useEffect(() => {
      const allTypes = Object.values(UsageType);
      setTypeOptions(commercialType ? allTypes.filter((t) => t.includes(commercialType)) : allTypes);
    }, [commercialType]);

    /** Render **/
    return (
      <Select
        value={val}
        id={id || 'usage-type'}
        name={name || 'usage-type'}
        placeholder="Choose..."
        options={typeOptions}
        onChange={({ value }) => onChange(value)}
        searchPlaceholder="Search types..."
        onSearch={(query) => filterTypes(query)}
        icon={<DocumentVideo />}
        dropAlign={dropAlign}
      />
    );
  }
);
