import { Box, Text } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { UsageEstimateDataRow } from './UsageEstimateDataRow';
import { UsageEstimateFooter } from './UsageEstimateFooter';
import { UsageEstimateHeader } from './UsageEstimateHeader';
import { UsageEstimateTitleRow } from './UsageEstimateTitleRow';
import { UsageEstimate } from '/lib/api';
import { useEstimateStore } from '/src/context';

export interface IUsageEstimatePanelProps {
  usageEstimate: UsageEstimate;
}

export const UsageEstimatePanel = observer(({ usageEstimate }: IUsageEstimatePanelProps) => {
  /** Context **/
  const estimateStore = useEstimateStore();

  /** State **/
  const [isUpdatingUsage, setIsUpdatingUsage] = useState(false);

  /** Computed **/
  const estimateItems = useMemo(
    () => usageEstimate.items?.toSorted((a, b) => (a.created_at > b.created_at ? 1 : -1)),
    [usageEstimate.items]
  );

  /** Methods **/

  /**
   * Update this panel's UsageEstimate
   * @param usageEstimate
   * @param usageEstimateIndex
   * @returns
   */
  const updateUsageEstimate = async (usageEstimate: UsageEstimate) => {
    if (isUpdatingUsage) return;
    else if (!estimateStore.currentEstimate) {
      console.warn('No estimate loaded!');
      return;
    }

    setIsUpdatingUsage(true);
    await estimateStore.updateUsageEstimate(usageEstimate);
    setIsUpdatingUsage(false);
  };

  const addEstimateItem = async () => {
    await estimateStore.createUsageEstimateItem({
      usageEstimateId: usageEstimate.id,
      title: 'New Usage Item',
    });
  };

  /** Render **/
  return (
    <Box margin="small" key={usageEstimate.id} fill="horizontal" round="15px" background="light-3" elevation="small">
      {/* Header */}
      <UsageEstimateHeader
        usageEstimate={usageEstimate}
        setUsageEstimate={(usageEstimate) => updateUsageEstimate(usageEstimate)}
      />

      {/* Data Table */}
      <Box border={{ side: 'bottom', size: 'xsmall' }}>
        <UsageEstimateTitleRow />
        {!!estimateItems?.length &&
          estimateItems?.map((item, i) => (
            <UsageEstimateDataRow key={item.id} usageEstimateItem={item} index={i} useColors />
          ))}
        {!estimateItems?.length && (
          <Box align="center" pad="medium">
            <Text>No Usage Estimate Items found.</Text>
          </Box>
        )}
      </Box>

      {/* Footer */}
      <UsageEstimateFooter addEstimateItem={addEstimateItem} />
    </Box>
  );
});
