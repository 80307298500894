import {
  Feature,
  FeatureService,
  Group,
  GroupService,
  PricingTier,
  PricingTierService,
  SubscriptionPlan,
  SubscriptionPlanService,
  TUpdateUserRequest,
  User,
  UserService,
} from '/lib/api';
import { makeObservable} from 'mobx';
import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually

export class AdminStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {});
  }

  // SUBSCRIPTION PLANS
  fetchSubscriptionPlans = async () => {
    try {
      const { subscriptionPlans } = await this.q(
        SubscriptionPlanService.listSubscriptionPlans(undefined, undefined, undefined, 'groups,pricing_tier,features')
      );
      return subscriptionPlans;
    } catch (e) {
      this.handleError(e);
    }
  };

  createSubscriptionPlan = async ({ name = 'New Subscription Plan', pricing_tier, groups, stripe_id, features }: Partial<SubscriptionPlan>) => {
    if (!name || !pricing_tier) {
      throw new Error('Name and price are required');
    }
    try {
      const { subscriptionPlan } = await this.q(
        SubscriptionPlanService.createSubscriptionPlan({
          name,
          pricing_tier_id: pricing_tier.id,
          group_ids: groups?.map((g) => g.id),
          stripe_id,
          feature_ids: features?.map((f) => f.id),
        })
      );
      return subscriptionPlan;
    } catch (e) {
      this.handleError(e);
    }
  };

  getSubscriptionPlan = async (id: string) => {
    try {
      const { subscriptionPlan } = await this.q(
        SubscriptionPlanService.getSubscriptionPlan(id, 'groups,pricing_tier,features')
      );
      return subscriptionPlan;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateSubscriptionPlan = async (request: SubscriptionPlan) => {
    if (!request.name || !request.pricing_tier) {
      throw new Error('Name and price are required');
    }
    try {
      const { subscriptionPlan } = await this.q(
        SubscriptionPlanService.updateSubscriptionPlan(request.id, {
          name: request.name,
          pricing_tier_id: request.pricing_tier.id,
          group_ids: request.groups?.map((g) => g.id),
          feature_ids: request.features?.map((f) => f.id),
          stripe_id: request.stripe_id,
        })
      );
      const updatedPlan = await this.getSubscriptionPlan(subscriptionPlan.id);
      return updatedPlan;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteSubscriptionPlan = async (id: string) => {
    try {
      await this.q(SubscriptionPlanService.deleteSubscriptionPlan(id));
    } catch (e) {
      this.handleError(e);
    }
  };

  // PRICING TIERS
  fetchPricingTiers = async () => {
    try {
      const { pricingTiers } = await this.q(
        PricingTierService.listPricingTiers(undefined, undefined, undefined, 'subscription_plans')
      );
      return pricingTiers;
    } catch (e) {
      this.handleError(e);
    }
  };

  createPricingTier = async ({ name = 'New Pricing Tier', price = 0, stripe_id }: Partial<PricingTier>) => {
    if (isNaN(price)) {
      throw new Error('Price is required');
    }
    try {
      const { pricingTier } = await this.q(PricingTierService.createPricingTier({ name, price, stripe_id }));
      return pricingTier;
    } catch (e) {
      this.handleError(e);
    }
  };

  getPricingTier = async (id: string) => {
    try {
      const { pricingTier } = await this.q(PricingTierService.getPricingTier(id, 'subscription_plans'));
      return pricingTier;
    } catch (e) {
      this.handleError(e);
    }
  };

  updatePricingTier = async ({ id, name, price, subscription_plans, stripe_id }: Partial<PricingTier>) => {
    if (!id || !name || (price && isNaN(price))) {
      throw new Error('Name and price are required');
    }
    const request = {
      name,
      price,
      subscription_plan_ids: subscription_plans?.map((p) => p.id),
      stripe_id,
    };
    try {
      await this.q(PricingTierService.updatePricingTier(id, request));
      const { pricingTier } = await this.q(PricingTierService.getPricingTier(id, 'subscription_plans'));
      return pricingTier;
    } catch (e) {
      this.handleError(e);
    }
  };

  deletePricingTier = async (id: string) => {
    try {
      await this.q(PricingTierService.deletePricingTier(id));
    } catch (e) {
      this.handleError(e);
    }
  };

  // FEATURES
  fetchFeatures = async () => {
    try {
      const { features } = await this.q(FeatureService.listFeatures());
      return features;
    } catch (e) {
      this.handleError(e);
    }
  };

  createFeature = async ({ name = 'New Feature' }: Partial<Feature>) => {
    try {
      const { feature } = await this.q(FeatureService.createFeature({ name }));
      return feature;
    } catch (e) {
      this.handleError(e);
    }
  };

  getFeature = async (id: string) => {
    try {
      const { feature } = await this.q(FeatureService.getFeature(id));
      return feature;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateFeature = async ({ id, name }: Partial<Feature>) => {
    if (!id || !name) {
      throw new Error('Feature name is required');
    }
    try {
      const { feature } = await this.q(FeatureService.updateFeature(id, { name }));
      return feature;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteFeature = async (id: string) => {
    try {
      await this.q(FeatureService.deleteFeature(id));
    } catch (e) {
      this.handleError(e);
    }
  };

  // USERS

  createUser = async ({ email, first_name, last_name, is_admin, is_registered, groups }: Partial<User>) => {
    if (!email || !first_name || !last_name) {
      throw new Error('Email, first name, and last name are required');
    }
    try {
      const { user: newUser } = await this.q(UserService.createUser({ email, first_name, last_name, send_verification_email: false, is_admin, is_registered, group_ids: groups?.map((g) => g.id), active_group_id: groups?.[0]?.id }));
      return newUser;
    } catch (e) {
      this.handleError(e);
    }
  };

  fetchUsers = async () => {
    try {
      const { users } = await this.q(UserService.listUsers(undefined, undefined, undefined, 'groups'));
      return users;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateUser = async (user: Partial<User>) => {
    if(!user.id) {
      throw new Error('User ID is required');
    }
    try {
      const { user: updatedUser } = await this.q(UserService.updateUser(user.id, {...user, group_ids: user.groups?.map((g) => g.id)} as TUpdateUserRequest));
      return updatedUser;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteUser = async (id: string) => {
    try {
      await this.q(UserService.deleteUser(id));
    } catch (e) {
      this.handleError(e);
    }
  };

  // GROUPS
  fetchGroups = async () => {
    try {
      const { groups } = await this.q(GroupService.listGroups(undefined, undefined, undefined, 'subscription_plan,owner,users'));
      return groups;
    } catch (e) {
      this.handleError(e);
    }
  };

  createGroup = async ({ name, stripe_id, invited_emails, subscription_plan, users, projects, is_jpc_authorizer, type }: Partial<Group>) => {
    if (!name || !subscription_plan) {
      throw new Error('Name and subscription plan are required');
    }
    const request = {
      name,
      subscription_plan_id: subscription_plan.id,
      user_ids: users?.map((u) => u.id),
      project_ids: projects?.map((p) => p.id),
      stripe_id,
      invited_emails,
      is_jpc_authorizer,
      type,
    };
    try {
      const { group: newGroup } = await this.q(GroupService.createGroup(request));
      return newGroup;
    } catch (e) {
      this.handleError(e);
    }
  };

  getGroup = async (id: string, relations: string) => {
    try {
      const { group } = await this.q(GroupService.getGroup(id, relations));
      return group;
    } catch (e) {
      this.handleError(e);
    }
  };

  updateGroup = async (group: Group) => {
    try {
      await this.q(GroupService.updateGroup(group.id, { ...group, subscription_plan_id: group.subscription_plan.id, owner_id: group.owner?.id, user_ids: group.users?.map((u) => u.id) }));
      const updatedGroup =  await this.getGroup(group.id, 'subscription_plan,owner,users');
      return updatedGroup;
    } catch (e) {
      this.handleError(e);
    }
  };

  deleteGroup = async (id: string) => {
    try {
      await this.q(GroupService.deleteGroup(id));
    } catch (e) {
      this.handleError(e);
    }
  };
}
