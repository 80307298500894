export interface IAddress {
  aptOrUnit?: string;
  number?: string;
  zip?: string;
  street?: string;
  state?: string;
  city?: string;
  country?: string;
  street_address?: string;
  lat?: number;
  lng?: number;
  [key: string]: string | number | undefined;
}

export const getAddressObject = (address_components: Array<google.maps.GeocoderAddressComponent>) => {
  interface IShouldBeComponent {
    [key: string]: Array<string>;
  }
  const ShouldBeComponent: IShouldBeComponent = {
    number: ['street_number'],
    zip: ['postal_code'],
    street: ['street_address', 'route'],
    aptOrUnit: ['subpremise'],
    state: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    country: ['country'],
  };

  const address: IAddress = {
    street_address: '',
    aptOrUnit: '',
    number: '',
    zip: '',
    street: '',
    state: '',
    city: '',
    country: '',
  };
  address_components.forEach((component) => {
    for (const shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === 'country') {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });
  address.street_address = `${address.number} ${address.street}`;
  return address;
};
