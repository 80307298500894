const getEnvironmentVariable = (unvalidatedEnvironmentVariable: string | undefined): string => {
  if (!unvalidatedEnvironmentVariable) {
    throw new Error(`Couldn't find environment variable: ${unvalidatedEnvironmentVariable}`);
  } else {
    return unvalidatedEnvironmentVariable;
  }
};

export const env = {
  apiHost: getEnvironmentVariable(process.env.API_HOST),
  apiPort: getEnvironmentVariable(process.env.API_PORT),
  apiBase: getEnvironmentVariable(process.env.API_BASE),
  apiVersion: getEnvironmentVariable(process.env.API_VERSION),
  posthog: {
    options: {
      api_host: getEnvironmentVariable(process.env.POSTHOG_HOST),
    },
    api_key: process.env.POSTHOG_KEY,
  },
};
