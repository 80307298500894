import { Box, Card, CardHeader, CardBody, Header, Text, CardFooter, Button } from 'grommet';
import { FavoriteFilled } from './icons/FilledIcons';
import { observer } from 'mobx-react-lite';
import { Project } from '/lib/api';
import { Delete } from '/src/components/ui';
import { useState, useEffect } from 'react';
import { useProjectStore, useUserStore } from '/src/context';
import { toast } from 'react-toastify';

export const ProjectCard = observer(({ project, onClick }: { project: Project; onClick: () => void }) => {
  const [favs, setFavs] = useState<string[]>([]);
  const projectStore = useProjectStore();
  const userStore = useUserStore();

  useEffect(() => {
    if(!userStore.user.favorite_project_ids) return;
    setFavs(userStore.user.favorite_project_ids);
  }, [userStore.user.favorite_project_ids]);

  const deleteProject = async () => {
    if (!project) {
      toast.error('An internal error has occurred. Please refresh the page and try again.');
      return;
    }
    projectStore.deleteProject(project.id);
  };

  const toggleLike = async (projectId: string) => {
    const newfavs = favs.includes(projectId) ? favs.filter((id) => id !== projectId) : [...favs, projectId];
    userStore.updateProfile({...userStore.user, favorite_project_ids: newfavs});
  };

  return (
    <Card background={'light-1'} margin="medium" height="medium" width="medium">
      <CardHeader pad="medium" onClick={onClick}>
        <Box direction='row' justify='between' fill>
          <Box direction='column'>
            <Header>{project.name}</Header>
            <Text size='xsmall'>({project.job_code})</Text>
          </Box>
          <Button icon={<FavoriteFilled filled={favs.includes(project.id) ? true : undefined} />} hoverIndicator onClick={() => toggleLike(project.id)} tip='Favorite this project'/>
        </Box>    
      </CardHeader>
      <CardBody pad="medium">
        <Box direction="column" align='start'>
          <Box margin="xsmall">
            <Text size="xsmall" margin="small">
                Advertiser: {project.client}
            </Text>
          </Box>
          <Box margin="xsmall">
            <Text size="xsmall" margin="small">
                Product: {project.product}
            </Text>
          </Box>
          <Box margin="xsmall">
            <Text size="xsmall" margin="small">
                Budget: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(project.budget || 0)}
            </Text>
          </Box>
          <Box margin="xsmall">
            <Text size="xsmall" margin="small">
                Created: {new Date(project.created_at).toLocaleDateString()}
            </Text>
          </Box>
          {project.is_union && (
            <Box margin="xsmall">
              <Text size="xsmall" margin="small">
                          Union
              </Text>
            </Box>)}
        </Box>
      </CardBody>
      <CardFooter pad={{ horizontal: 'small' }} background="light-2" justify="end">
        <Box direction="row" justify="between" gap="small">
          <Box direction="row" margin="small">
            <Delete onClick={() => deleteProject()} name={project.name} hoverIndicator />
          </Box>
        </Box>
      </CardFooter>
    </Card>
  );
});
