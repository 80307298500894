import { BaseStore } from './baseStore'; // for some dumb reason, these have to be imported individually
import { RootStore } from './rootStore'; // for some dumb reason, these have to be imported individually
import {
  Performer,
  PerformerService,
  TCreatePerformerRequest,
  TUpdatePerformerRequest,
} from '/lib/api';

export class PerformerStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  /**
   * Performers
   */

  createPerformer = async ({ roleIds, lastName, firstName, type, projectId   }: TCreatePerformerRequest) => {
    try {
      const { performer } = await PerformerService.createPerformer({ roleIds, lastName, firstName, type, projectId });
      return performer;
    } catch (e) {
      this.handleError(e);
    }
  };

  updatePerformer = async (performer: Performer) => {
    const updateRequest: TUpdatePerformerRequest = {
      ...performer,
      roleIds: performer.roles.map((role) => role.id),
      lastName: performer.last_name || '',
      firstName: performer.first_name,
      type: performer.type,
    };
    try {
      const { performer: updatedPerformer } = await PerformerService.updatePerformer(performer.id, updateRequest);
      return updatedPerformer;
    } catch (e) {
      this.handleError(e);
    }
  };

  deletePerformer = async (performerId: string) => {
    try {
      await PerformerService.deletePerformer(performerId);
    } catch (e) {
      this.handleError(e);
    }
  };

  getPerformer = async (performerId: string) => {
    try {
      const { performer } = await PerformerService.getPerformer(performerId);
      return performer;
    } catch (e) {
      this.handleError(e);
    }
  };

  listPerformersByProjectId = async (projectId: string) => {
    try {
      const { performers } = await PerformerService.listPerformers(projectId);
      return performers;
    } catch (e) {
      this.handleError(e);
    }
  };
}
