import { Box, Button } from 'grommet';
import { Add, FormNext, FormPrevious } from 'grommet-icons';

interface IProjectWizardFooterProps {
    activeSlide: number;
    setActiveSlide: (slide: number) => void;
    next: boolean;
    previous: boolean;
    createFn: () => void;
    valid: () => boolean;
}

export const ProjectWizardFooter = ({activeSlide, setActiveSlide, next, previous, createFn, valid}: IProjectWizardFooterProps) => {
  return (
    <Box direction='row' justify='between' pad='small'>
      <Button hoverIndicator label="Back" icon={<FormPrevious color="plain" />} onClick={() => setActiveSlide(activeSlide-1)} disabled={!previous}/>
      {next ? 
        <Button hoverIndicator label="Next" icon={<FormNext color="plain" />} type='submit' onClick={() => valid() ? setActiveSlide(activeSlide+1) : undefined} disabled={!next || !valid()}/> :
        <Button hoverIndicator icon={<Add />} onClick={createFn} type='submit' label="Create Project"/>}
    </Box>
  );
};