import { Grid, Box, Header, Footer } from 'grommet';
import { ReactNode } from 'react';
import { HeaderNav } from '../nav/HeaderNav';
import { FooterNav } from '../nav/FooterNav';

export const Layout = ({ children }: { children: ReactNode; showSidebar?: boolean }) => {
  return (
    <Grid
      height={{ min: '100vh', max: '100vh' }}
      rows={['auto', 'flex', 'auto']}
      columns={['auto', 'flex']}
      areas={[
        { name: 'header', start: [0, 0], end: [1, 0] },
        { name: 'main', start: [0, 1], end: [1, 1] },
        { name: 'footer', start: [0, 2], end: [1, 2] },
      ]}
      gap="none"
    >
      <Header gridArea="header" background="brand" direction="row">
        <HeaderNav />
      </Header>
      <Box gridArea="main" background="background-back" overflow="auto">
        {children}
      </Box>
      <Footer gridArea="footer" background="brand" pad="small">
        <FooterNav />
      </Footer>
    </Grid>
  );
};
