import { Box, Button, Layer, Select, Text, TextInput } from 'grommet';
import { Multimedia } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { CommercialType } from '/lib/api';

export interface ICreateCommercialModalProps {
  addCommercial: (type: CommercialType, durationInSeconds: number, title?: string) => Promise<void>;
  setShowModal: (isVisible: boolean) => void;
}

export const CreateCommercialModal = observer(({ addCommercial, setShowModal }: ICreateCommercialModalProps) => {
  /** State **/
  const [title, setTitle] = useState('');
  const [type, setType] = useState<CommercialType>();
  const [durationInSeconds, setDurationInSeconds] = useState(0);

  /** Methods **/
  const createCommercial = () => {
    if (!type) return;
    addCommercial(type, durationInSeconds, title);
    setShowModal(false);
  };

  /** Render **/
  return (
    <Layer
      onEsc={() => setShowModal(false)}
      onClickOutside={() => setShowModal(false)}
      position="center"
      modal={true}
      margin="large"
    >
      <Box
        direction="column"
        align="start"
        pad="small"
        justify="around"
        width="medium"
        height="medium"
        background="light-1"
        round="small"
      >
        <Box direction="row" gap="small" pad="small" align="center" fill="horizontal">
          <Box width="28%">
            <Text>Title:</Text>
          </Box>
          <Box fill="horizontal">
            <TextInput
              id="title"
              placeholder="New Commercial"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>
        </Box>

        <Box direction="row" gap="small" pad="small" align="center" fill="horizontal">
          <Box width="28%">
            <Text>Type:</Text>
          </Box>
          <Box fill="horizontal">
            <Select
              id="commercial-type"
              name="commercial-type"
              placeholder="Choose..."
              value={type}
              options={Object.values(CommercialType)}
              onChange={({ value }) => setType(value)}
              icon={<Multimedia />}
            />
          </Box>
        </Box>

        <Box direction="row" gap="small" pad="small" align="center" fill="horizontal">
          <Box width="28%">
            <Text>Duration:</Text>
          </Box>
          <Box fill="horizontal" direction="row" align="center" gap="xsmall">
            <TextInput
              id="duration-in-seconds"
              name="duration-in-seconds"
              value={durationInSeconds}
              type="number"
              onChange={(e) => setDurationInSeconds(parseInt(e.target.value ?? 0))}
            />
            <Text>seconds</Text>
          </Box>
        </Box>

        <Button
          pad="small"
          margin="small"
          hoverIndicator
          onClick={() => createCommercial()}
          label="Add Commercial"
          disabled={!type || typeof durationInSeconds !== 'number'}
        />
      </Box>
    </Layer>
  );
});
