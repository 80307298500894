/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum GroupType {
    ADVERTISER = 'advertiser',
    AD_AGENCY = 'ad_agency',
    PRODUCTION_COMPANY = 'production_company',
    TALENT = 'talent',
    TALENT_AGENCY = 'talent_agency',
}
