import { Box, RadioButtonGroup, SelectMultiple, Text } from 'grommet';
import { CommercialType } from '/lib/api';
import { Hint } from '.';

interface IMediaTabProps {
    media: string[] | undefined;
    setMedia: (value: CommercialType[] | undefined) => void;
    mediaOptions: string[];
    exclusivity: string | undefined;
    setExclusivity: (value: string | undefined) => void;
}

export const MediaTab = (props: IMediaTabProps) => {
  const {media, setMedia, mediaOptions, exclusivity, setExclusivity} = props;

  return (
    
    <Box pad="medium" overflow={{vertical: 'auto'}} direction="row">
      <Box flex="grow" gap="medium" width="60%">
        <Text>Does the client want exclusivity?</Text>
        <RadioButtonGroup direction="row" name="exclusivity" options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]} value={exclusivity} onChange={(e) => setExclusivity(e.target.value)}/>
        <Text>What media will be produced for this project?</Text>
        <SelectMultiple
          showSelectedInline
          placeholder="Select"
          value={media as string[] | undefined} 
          options={mediaOptions} 
          labelKey="label" 
          valueKey={{ key: 'value', reduce: true }}
          onChange={({value}) => setMedia(value)}
          width="medium"
        />
      </Box>
      <Box width="40%">
        {exclusivity === 'yes' || media?.includes('TV') ? (
          <Hint>
            <Text>Heads up! This commercial must be shot under the broadcast contract because {exclusivity === 'yes' ? 'the client wants exclusivity' : 'it will be on TV'}.</Text>
          </Hint>
        ) : null}
      </Box>
    </Box>
    
  );
};