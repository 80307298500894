import { Box, Button, Text } from 'grommet';
import { Add } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { CreateUsageEstimateModal } from '..';
// import { UsageEstimatePanel } from './UsageEstimatePanel';
import { UsageEstimatePanel } from './UsageEstimatePanel';
import { Commercial, EstimateStatus, TCommercialId } from '/lib/api';
import { useEstimateStore } from '/src/context';

export interface IUsageEstimateListProps {
  commercial: Commercial;
}

export const UsageEstimateList = observer(() => {
  /** Context **/
  const estimateStore = useEstimateStore();

  /** State **/
  const [showAddModal, setShowAddModal] = useState(false);

  /** Computed **/
  const canEdit = estimateStore.canEditCurrent;
  const isDraft = estimateStore.currentEstimate?.status === EstimateStatus.DRAFT;

  const usageEstimates = useMemo(
    () => estimateStore.currentEstimate?.usage_estimates?.toSorted((a, b) => (a.created_at > b.created_at ? 1 : -1)),
    [estimateStore.currentEstimate?.usage_estimates]
  );

  /** Methods **/
  const addUsageEstimate = async (title: string, commercialId?: TCommercialId) => {
    if (!estimateStore.currentEstimate) return;

    await estimateStore.createUsageEstimate({ estimateId: estimateStore.currentEstimate.id, commercialId, title });
  };

  /** Render **/
  return (
    <Box gap="small" margin={{ horizontal: 'small', bottom: 'medium' }} pad="small">
      {isDraft && canEdit && (
        <Box alignSelf="end" pad={{ vertical: 'xsmall' }} margin={{ left: 'medium' }}>
          <Button
            secondary
            label={
              <Text size="small" weight="bold">
                ADD USAGE ESTIMATE
              </Text>
            }
            icon={<Add size="16px" />}
            tip="Add estimate item"
            onClick={() => setShowAddModal(true)}
          />
        </Box>
      )}
      <Box align="center">
        {!!usageEstimates?.length &&
          usageEstimates.map((usageEstimate) => (
            <UsageEstimatePanel key={usageEstimate.id} usageEstimate={usageEstimate} />
          ))}
        {!usageEstimates?.length && (
          <Box align="center">
            <Text>No Usage Estimates found.</Text>
          </Box>
        )}
      </Box>
      {isDraft && showAddModal && (
        <CreateUsageEstimateModal addUsageEstimate={addUsageEstimate} setShowModal={setShowAddModal} />
      )}
    </Box>
  );
});
