import { Box, CheckBox, DateInput, Select, Text } from 'grommet';
import { Yoga } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { SessionActivityRow } from '.';
import { AddNewButton, TalonTip } from '../..';

import dayjs from 'dayjs';
import {
  Costume,
  Location,
  Prop,
  Role,
  RoleGroup,
  Script,
  Session,
  SessionActivity,
  SessionActivityType,
  TEstimateId,
  TUpdateSessionRequest,
} from '/lib/api';
import {
  useCostumeStore,
  useEstimateStore,
  useProjectStore,
  usePropStore,
  useRoleStore,
  useSessionActivityStore,
  useSessionStore,
} from '/src/context';

export interface ISessionRowProps {
  sessionId: string;
  onChecked: () => void;
  checked: boolean;
  locations: Location[];
}

export const SessionRow = observer(({ sessionId, locations, onChecked, checked }: ISessionRowProps) => {
  /** Context **/
  const projectStore = useProjectStore();
  const sessionStore = useSessionStore();
  const costumeStore = useCostumeStore();
  const estimateStore = useEstimateStore();
  const propStore = usePropStore();
  const roleStore = useRoleStore();
  const sessionActivityStore = useSessionActivityStore();

  /** State **/
  const [scripts, setScripts] = useState<Script[]>([]);
  const [session, setSession] = useState<Session | undefined>(undefined);
  const [costumes, setCostumes] = useState<Costume[]>([]);
  const [props, setProps] = useState<Prop[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [roleGroups, setRoleGroups] = useState<RoleGroup[]>([]);

  /** Methods **/
  const sortSessionActivities = (s: Session) => {
    if (!s || !s.activities) {
      console.log('session/activites not set, cant sort');
      return;
    }
    s?.activities?.sort((a, b) => {
      if (new Date(a.start_time) < new Date(b.start_time)) {
        return -1;
      }
      if (new Date(a.start_time) > new Date(b.start_time)) {
        return 1;
      }
      return 0;
    });
    return s;
  };

  const addSessionActivity = async () => {
    if (!session) return;
    const previousActivity: SessionActivity | undefined = session.activities[session.activities.length - 1];
    const startTime = dayjs(previousActivity?.end_time ?? `${session.date}T09:00:00`).toISOString();
    const endTime = previousActivity
      ? dayjs(previousActivity.end_time).add(1, 'hour').toISOString()
      : new Date(`${session.date}T17:00:00`).toISOString();
    const sessionActivity = await sessionActivityStore.createSessionActivity(
      session.id,
      SessionActivityType.WORK_TIME,
      startTime,
      endTime,
      session.script.id
    );
    if (sessionActivity) {
      const activities = [...session.activities, sessionActivity];
      const updatedSession = { ...session, activities };

      const sessionEstimates = (await sessionStore.getSession(session.id))?.session_estimates;
      if (sessionEstimates) updatedSession.session_estimates = sessionEstimates;

      setSession(sortSessionActivities(updatedSession));
    }
  };

  const updateSession = async (update: TUpdateSessionRequest) => {
    console.log('update', update);
    if (!session) {
      console.log('no session');
      return;
    }
    const updatedSession = await sessionStore.updateSession(session.shoot.id, session.id, update);
    updatedSession && setSession(sortSessionActivities(updatedSession));
  };

  const updateSessionActivity = async (update: SessionActivity) => {
    if (!session) return;
    const sa = await sessionActivityStore.updateSessionActivity(update);
    if (!sa) return;

    const activities = session.activities?.map((a) => (a.id === sa.id ? sa : a));
    const updatedSession = { ...session, activities };

    const sessionEstimates = (await sessionStore.getSession(session.id))?.session_estimates;
    if (sessionEstimates) updatedSession.session_estimates = sessionEstimates;

    setSession(sortSessionActivities(updatedSession));
  };

  const deleteSessionActivity = async (activityId: string) => {
    if (!session) return;
    await sessionActivityStore.deleteSessionActivity(activityId);

    const activities = session?.activities?.filter((a) => a.id !== activityId);
    const updatedSession = { ...session, activities };

    const sessionEstimates = (await sessionStore.getSession(session.id))?.session_estimates;
    if (sessionEstimates) {
      updatedSession.session_estimates = sessionEstimates;
      const refreshed: Record<TEstimateId, boolean> = {};
      for (const sessionEstimate of sessionEstimates) {
        if (refreshed[sessionEstimate.estimate.id]) continue;
        await estimateStore.refreshEstimateTotals(sessionEstimate.estimate.id);
        refreshed[sessionEstimate.estimate.id] = true;
      }
    }

    setSession(sortSessionActivities(updatedSession));
  };

  /** Effects **/
  useEffect(() => {
    const getScripts = async () => {
      if (!projectStore.currentProject) return;
      const s = await projectStore.getScriptsByProject(projectStore.currentProject.id);
      setScripts(s || []);
    };
    getScripts();
  }, []);

  useEffect(() => {
    const getSession = async () => {
      const s = await sessionStore.getSession(sessionId);
      s && setSession(sortSessionActivities(s));
    };
    getSession();
  }, [sessionId]);

  useEffect(() => {
    const getDropDownOptions = async () => {
      if (!session?.script?.id) return;
      const [r, rg, c, p] = await Promise.all([
        roleStore.getRolesByScriptId(session.script.id),
        roleStore.getRoleGroupsByScriptId(session.script.id),
        costumeStore.getCostumesByScriptId(session.script.id),
        propStore.getPropsByScriptId(session.script.id),
      ]);
      setRoles(r || []);
      setRoleGroups(rg || []);
      setCostumes(c || []);
      setProps(p || []);
    };
    getDropDownOptions();
  }, [session?.script?.id]);

  /** Render **/
  return (
    <Box direction="column">
      <Box direction="row" align="start" background="light-2">
        <Box direction="row" gap="small" pad="small">
          <CheckBox onChange={onChecked} checked={checked} />
          <DateInput
            value={session && new Date(`${session.date}T00:00:00`).toISOString()}
            format="mm/dd/yyyy"
            calendarProps={{ disabled: sessionStore.getSessionDates(sessionId) }}
            onChange={(event) => session && typeof event.value === 'string' && updateSession({ date: event.value })}
          />
        </Box>
        <Box direction="row" gap="small" pad="small" alignSelf="center">
          {!session?.activities.length && (
            <Select
              options={scripts}
              value={session?.script}
              labelKey="name"
              valueKey="id"
              onChange={({ value: script }) => updateSession({ scriptId: script.id })}
              placeholder="Choose script..."
              size="medium"
            />
          )}
          {!!session?.activities.length && <Text>{session.script?.name}</Text>}
        </Box>
        <Box direction="row" gap="small" pad="small" align="end">
          <AddNewButton
            icon={
              <TalonTip content={<Text>Add An Activity</Text>}>
                <Yoga />
              </TalonTip>
            }
            onClick={() => addSessionActivity()}
            disabled={!session?.script?.id}
          />
        </Box>
      </Box>
      <Box direction="column" gap="small">
        {session &&
          session.activities?.map((activity) => (
            <SessionActivityRow
              script={session.script}
              session={session}
              costumes={costumes}
              props={props}
              roles={roles}
              roleGroups={roleGroups}
              locations={locations}
              deleteSessionActivity={deleteSessionActivity}
              activity={activity}
              updateSessionActivity={(update) => updateSessionActivity({ ...activity, ...update })}
              key={activity.id}
            />
          ))}
      </Box>
    </Box>
  );
});
