import { Box } from 'grommet';
import { CircleInformation } from 'grommet-icons';
import { ReactNode } from 'react';

interface IHintProps {
    children: ReactNode;
}
export const Hint = ({children}: IHintProps) => {
  return (
    <Box pad="medium" gap="medium" background="hint-background" elevation='small' round='small' direction="column">
      <Box direction='row' gap='small'>
        <CircleInformation />
        {children}
      </Box>
    </Box>
  );
};
