import { Anchor, Nav, Text } from 'grommet';

export const FooterNav = () => {
  const year = new Date().getFullYear();
  return (
    <Nav direction="row" background="brand" justify="center" flex="grow" pad="small">
      <Text>Copyright &copy; {year}</Text>
      <Anchor href="https://www.aktarytech.com" target="_blank">
        AktaryTech
      </Anchor>
    </Nav>
  );
};
